import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import {ArrowDropDownRounded, ArrowRightRounded, CheckCircleRounded,HighlightOffRounded, DoNotDisturbOnRounded } from '@mui/icons-material';


import {grey} from "@mui/material/colors"
import {Box, Button, Divider, Typography, Tabs, Tab} from "@mui/material";
import {useContext} from "react";
import MainContext from "../../context";
import {SidebarHeader, SidebarFooter} from "./index";
import { tabsData } from "../data/tabsData.sidebar";

const SidebarTabs = () => {

    const {tabClick, pageNumber, handlePageNumber, setDrawerOpen } = useContext(MainContext);

    const data = tabsData();
    const [openCollapse, setOpenCollapse] = React.useState(false);

    const handleClick = (id) => {
        //setOpenCollapse(!openCollapse);
        setOpenCollapse((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
    };
    const tabs2 = [
        { label: "Airworthiness"},
        { label: "ISM"},
        { label: "Projects" },
        { label: "Proposals" },
    ];
    const tabs3 = [
        { label: "Quality system"},
        { label: "Projects"},
    ];
    const tabs4 = [
        { label: "Reports"},
        { label: "Performance"},
        { label: "Administrator"},
    ];
    const tabs1 = [
        { label: "Dashboard", subTab: false, subTabItems: []},
        { label: "DOA",  subTab: true, subTabItems: tabs2},
        { label: "POA" , subTab: true, subTabItems: tabs3},
        { label: "R & D", subTab: false, subTabItems: [] },
        { label: "Management", subTab: true, subTabItems: tabs4 },
    ];
    let temp = 0;
    let tabIndexes = {}
    for (let i=0; i < tabs1.length; i++)
    {
        if (tabs1[i].subTab === false){
            tabIndexes[i.toString()] = temp;
            temp += 1;
        }else{
            for (let j=0; j < (tabs1[i].subTabItems).length; j++){
                tabIndexes[i.toString() + "+" + j.toString()] = temp;
                temp += 1;
            }
        }
    }

    return(
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <SidebarHeader   />

            <Box
                sx = {{mt: "3rem",textAlign: "left"}}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: "#153250", fontSize: "0.75rem"}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    value={pageNumber}
                    onChange={handlePageNumber}
                    id = "sidbarlist"
                >
                    {tabs1.map((tab, index) => (
                        <Box
                            key={index}
                        >
                            <ListItemButton
                                key={index}
                                onClick={() =>  {tab.subTab ? handleClick(index) : tabClick(index, tab.label )}}
                            >
                                {tab.subTab ? (openCollapse[index] ? <ArrowRightRounded sx={{color: "#c3bebe", ml: -1}}/> : <ArrowDropDownRounded sx={{color: "#c3bebe",  ml: -1}}/>) : null}
                                <ListItemText key={index} primary= {tab.label} />
                            </ListItemButton>
                            {tab.subTab ?
                                <Collapse
                                    key={"Collapse" + index}
                                    in={openCollapse[index]} timeout="auto" unmountOnExit>
                                    <List
                                        key={"list" + index}
                                        component="div"
                                        disablePadding
                                    >
                                        {tab.subTabItems.map((subtab, subindex) => (
                                            <ListItemButton sx={{ pl: 4 }}
                                                            key={parseInt(index.toString() + subindex.toString())}
                                                            onClick={() => tabClick(tabIndexes[index.toString() + "+" + subindex.toString()] , tab.label + " - " + subtab.label) }
                                            >
                                                <ListItemText key={parseInt(index.toString() + subindex.toString())} primary={subtab.label} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                               </Collapse>
                              : null}
                        </Box>
                    ))}
                </List>
            </Box>

            <SidebarFooter />

        </Box>

    );
}

export default SidebarTabs;
