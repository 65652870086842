import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {Login, TaskManagement} from "./containers/index";
import { RequireAuth } from "react-auth-kit";

function App() {
    return (
            <Routes>
                <Route
                    path="/ProjectManagement"
                    element={
                        <RequireAuth loginPath="/">
                            <TaskManagement />
                        </RequireAuth>
                    }
                >
                </Route>
                <Route path="/" element={<Login />}></Route>
            </Routes>
    );
}

export default App;