import React from "react";
import { useState, useContext, useEffect, useRef} from "react";
import {Box, Button, Divider, Paper, Typography, Slide, Alert} from "@mui/material";
import MainContext from "../../context";
import {TableRows} from "./index";
import {grey} from "@mui/material/colors";
import { Overlay } from 'react-portal-overlay';
import Grid from "@mui/material/Unstable_Grid2";
import {useAuthUser} from "react-auth-kit";
import Snackbar from "@mui/material/Snackbar";
import Grow from "@mui/material/Grow";
import Fade from '@mui/material/Fade';




function createArray(N) {
    return [...Array(N).keys()].map(i => i );
}

const TaskPage = ({pageTitle}) => {
    const {handleSnackClose, snackOpen, setSnackOpen, snackContent, deleteProject, temp, setTemp, setProjectData, Staff, openModal, setOpenModal, projectInfoUpdate, targetRefIndex, elRefs, type, projData, projList} = useContext(MainContext);
    const auth = useAuthUser();
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const originalRef = useRef(null);

    let typeProject = projList.filter(row => row[5] === type)
    let projectIndexes = []
    for (let i=0; i < typeProject.length; i++ ){
        projectIndexes.push(projList.findIndex(row => row[1] === typeProject[i][1] ))
    }

    useEffect(() => {
        let  targetRef = originalRef;
        if (targetRefIndex !== ""){
            targetRef = elRefs[targetRefIndex];
        }
         targetRef.current.scrollIntoView({
             behavior: 'smooth',
             block: 'start',
         });

    }, []);

    const closeModal = () => {
        setOpenModal(false);
        setTemp({["index"]:"", ["number"]: "", ["title"]: "",["customer"]:"", ["manager"]: "", ["priority"]:"", ["type"]: ""});
    }

    const TempProject = () =>{
        setOpenModal(true);
        setTemp({["index"]:"",["number"]: "",["title"]: "",["customer"]:"", ["manager"]: "", ["priority"]:"", ["type"]: type});

    }

    let isDisabled = true;
    if (auth().user_record[3] === "No" && auth().user_record[5] === "No"){
        isDisabled = false;
    }


        return (
            <>
                <Box
                    sx={{
                        height: "100vh",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: "#F0F2F3",
                    }}
                >
                    <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}} ref={originalRef}>
                        <h3 className= "headerClass"  > {pageTitle} </h3>
                        <Typography  variant="caption" sx={{color: "#031830"}} > Find all information per each project here. </Typography>
                        <Box sx = {{textAlign: "right", display: "inline-block", float: "right" }} id = "search">
                            <Paper
                                component="form"

                                sx={{  display: 'flex', alignItems: 'left', width:250, textAlign: "left", direction: "ltr", }}
                            >
                                <Button
                                    onClick={TempProject}
                                    sx={{ width:170, border: "unset"}}
                                    disabled={!isDisabled}
                                >
                                    + Add new project
                                </Button>
                            </Paper>
                        </Box>
                    </Box>
                    <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />
                    <Box
                       sx = {{
                       height: "100%",
                       overflowY: "scroll",
                       display: 'flex',
                       flexDirection: 'column',
                       fontSize: "1.2rem",
                       mb: 2, ml:4, mr:4,
                        }}
                    >
                        <Box sx={{mt: 5}}>
                            {typeProject.length > 0 ?
                                typeProject.map((item, key) => {
                                    let index = projectIndexes[key]
                                    let indexes = [];
                                    if (projData.length > 0){
                                        indexes = createArray(projData[index].length);
                                    }

                                    return (
                                        <Box
                                            key = {key}
                                            id="projectContainer"
                                            sx = {{backgroundColor: "white", borderRadius: "15px", mb: 3}}>
                                            <Box key={key} sx={{ mt:2, ml:2, mr: 2}}  ref={elRefs[key]}>
                                                <TableRows
                                                    index={index}
                                                    table_rows={projData[index]}
                                                    indexes = {indexes}
                                                    item = {item}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                })
                                :
                                <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "600px", textAligh: "center", borderRadius: "3px"}} >
                                    <Box sx = {{textAlign: "center", p : 3}}>
                                        <Typography  > There is no active project for this category! </Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                <Overlay open={openModal} onClose={() => setOpenModal(false)} >
                    <Box className = "modal" id = "projectDefinition" sx={{minWidth: 500, minHeight: 320, }}>
                        <Box sx={{mt: 3}}>
                            <Box sx={{ml: 3, mb: 2, }}>  <h5 style={{ fontWeight: 700, textAlign: "left", color: "#031830"}} > Project Information</h5>
                            </Box>
                            <Box sx={ {m:3}}>
                                <Grid container sx={{mb:1}}>
                                    <Grid xs={4}>
                                        <Typography variant="caption" sx={{fontWeight: 700}}>Project Title:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <input
                                            type="text"
                                            value={temp.title}
                                            onChange=  {(event) => setProjectData(event)}
                                            name="title"
                                            className="selectOption"
                                            style={{textAlign:"left", width: "100%"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mb:1}}>
                                    <Grid xs={4}>
                                        <Typography variant="caption" sx={{fontWeight: 700}}> Project Customer: </Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <input
                                            //key = {index}
                                            type="text"
                                            value={temp.customer}
                                            onChange=  {(event) => setProjectData(event)}
                                            name="customer"
                                            className="selectOption"
                                            style={{textAlign:"left", width: "100%"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mb:1}}>
                                    <Grid xs={4}>
                                        <Typography variant="caption" sx={{fontWeight: 700}}> Project Manager:</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <select
                                            name="manager"
                                            value={temp.manager}
                                            onChange=  {(event) => setProjectData(event)}
                                            className="selectOption"
                                            style = {{width : "100%"}}
                                        >
                                            <option value="">  </option>
                                            {Staff.map((member) => (
                                                <option key={member[0]} value={member[0]}>
                                                    {member[0]}
                                                </option>
                                            ))}
                                        </select>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{mb:1}}>
                                    <Grid xs={4}>
                                        <Typography variant="caption" sx={{fontWeight: 700}}> Project Priority: </Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <select
                                            name="priority"
                                            value={temp.priority}
                                            onChange=  {(event) => setProjectData(event)}
                                            className="selectOption myClass"
                                            style={{width : "100%", fontWeight: 400}}
                                        >
                                            <option value=""> </option>
                                            <option value = "High"> High </option>
                                            <option value = "Critical"> Critical </option>
                                            <option value = "Medium"> Medium </option>
                                            <option value = "Low"> Low </option>
                                        </select>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box id="confirm" sx={{backgroundColor: "#f0f3fc", mt: 5, textAlign: "right"}}>
                                <Button

                                    onClick={(event) => projectInfoUpdate(event)}
                                >
                                    Save
                                </Button>
                                <Button
                                    sx={{ml:3}}
                                    onClick={(event) => deleteProject(event)}
                                >
                                    Delete
                                </Button>
                                <Button
                                    sx={{ml:3, mr: 2}}
                                    onClick={(event) => closeModal()}
                                >
                                    Cancel
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                </Overlay>





            </>
        );
}
export default TaskPage;
