import React from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import {StaffGuanttChart, ViewSwitcher} from "./index";
import "gantt-task-react/dist/index.css";
import { GetStartEndDateForProject, InitTasks } from "./helper";
import {Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
const GuanttChart = ({Cat}) => {
    const [view, setView] = React.useState(ViewMode.Day);

    const tasks = InitTasks({Cat});

    const [isChecked, setIsChecked] = React.useState(true);

    let columnWidth = 50;
    if (view === ViewMode.Month) {
        columnWidth = 200;
    } else if (view === ViewMode.Week) {
        columnWidth = 150;
    }
    // const handleTaskChange = (task) => {
    //     console.log("On date change Id:" + task.id);
    //     let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    //     if (task.project) {
    //         const [start, end] = GetStartEndDateForProject(newTasks, task.project);
    //         const project =
    //             newTasks[newTasks.findIndex((t) => t.id === task.project)];
    //         if (
    //             project.start.getTime() !== start.getTime() ||
    //             project.end.getTime() !== end.getTime()
    //         ) {
    //             const changedProject = { ...project, start, end };
    //             newTasks = newTasks.map((t) =>
    //                 t.id === task.project ? changedProject : t
    //             );
    //         }
    //     }
    //     setTasks(newTasks);
    // };
    // const handleTaskDelete = (task) => {
    //     const conf = window.confirm("Are you sure about " + task.name + " ?");
    //     if (conf) {
    //         setTasks(tasks.filter((t) => t.id !== task.id));
    //     }
    //     return conf;
    // };
    // const handleProgressChange = async (task) => {
    //     setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //     console.log("On progress change Id:" + task.id);
    // };
    // const handleDblClick = (task) => {
    //     alert("On Double Click event Id:" + task.id);
    // };
    // const handleSelect = (task, isSelected) => {
    //     console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
    // };
    // const handleExpanderClick = (task) => {
    //     setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //     console.log("On expander click Id:" + task.id);
    // };

    if (tasks.length > 0){
        return (
            <>
                <div id = "guantchart">
                    {/*<ViewSwitcher*/}
                    {/*    //onViewModeChange={(viewMode) => setView(viewMode)}*/}
                    {/*    onViewListChange = {setIsChecked}*/}
                    {/*    isChecked = {isChecked}*/}
                    {/*/>*/}

                    <Gantt
                        tasks={tasks}
                        //viewMode={view}
                        //onDateChange={handleTaskChange}
                        //onDelete={handleTaskDelete}
                        //onProgressChange={handleProgressChange}
                        //onDoubleClick={handleDblClick}
                        //onSelect={handleSelect}
                        //onExpanderClick={handleExpanderClick}
                        listCellWidth={isChecked ? "155px" : ""}
                        //ganttHeight={300}
                        columnWidth={columnWidth}
                    />
                </div>

            </>

        );
    }else{
        return (
            <Box sx = {{ margin: "15% auto", boxShadow: "0 0 10px 5px #0000002e",maxWidth: "600px", textAligh: "center", borderRadius: "3px"}} >
                <Box sx = {{textAlign: "center", p : 3}}>
                    <Typography  > There is no task yet! </Typography>
                </Box>
            </Box>
        );
    }

};
export default GuanttChart;
