import React, {useEffect} from "react";
import {useContext, useState, createRef} from "react";
import {Box, Button, Divider,IconButton, Paper,Table, TableBody, FormControl, Avatar, Collapse,
    TableCell,TableContainer,TableRow,Typography, Card,CardContent, AppBar, Tabs, Tab, useTheme, Radio, RadioGroup, FormControlLabel,    } from "@mui/material";
import {useAuthUser} from 'react-auth-kit';
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "react-slick";
import DatePicker from 'react-datepicker';
import SwipeableViews from "react-swipeable-views";
import {} from "@mui/material/styles";
import PropTypes from "prop-types";
import {FiberManualRecordRounded, ArrowDropDownRounded, ArrowRightRounded} from '@mui/icons-material';
import axios, {AxiosError} from "axios";
import MainContext from "../../context";
import {TimeCalculate} from "./test4";
import { tabsData } from "../../components/data/tabsData.sidebar";
import {format} from "date-fns";

function TaskTable({ table_rows, type, scrollEffect}) {
    const {setTargetRefIndex, tabClick, elRefs, setElRefs, projList, projData } = useContext(MainContext);
    const data = tabsData()[0];

    if (table_rows.length > 0) {
        return  (table_rows.map((rowsData, ind) => {
            let content = projList[rowsData.projectId][2] + " - " + projData[rowsData.projectId][rowsData.taskId][1];
            let projectNumber = rowsData.projectId;
            return (
                <TableRow key={ind} className ="myTasksRow">

                    <TableCell sx={{width: "60%", pl: 2}}>
                        <Button
                            className = "myTasks"
                            onClick = {(event) => scrollEffect (projectNumber)}
                            sx = {{textTransform: "unset", pl: 2}}
                        >
                            {content}
                        </Button>
                    </TableCell>

                    <TableCell sx={{width: "20%"}}>
                        <Typography variant="caption" className = "myTasks">
                            Priority: {" "} {projData[rowsData.projectId][rowsData.taskId][5]}
                        </Typography>
                    </TableCell>

                    <TableCell sx={{width: "20%"}}>
                        <Typography variant="caption" className = "myTasks">
                            Progress: {" "} {projData[rowsData.projectId][rowsData.taskId][7]}  %
                        </Typography>
                    </TableCell>

                </TableRow>
            );
        }));
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Dashboard = ({pageTitle}) => {
    const {setCheckoutBtn, Staff, checksInput, setChecksInput, checkinBtn, checkoutBtn, checkinDate, setCheckinDate, timeSheet, setTimeSheet, totalHours, setTotalHour, overTime, setOvertime,checkInOutTable,confirmedOffDate, setConfirmedOffDate, declinedOffDate, setDeclinedOffDate, waitingOffDate, setWaitingOffDate, offSiteDates, setOffSiteDates,setProjectAnchorEl, setSnackContent, setSnackOpen, setError, setTargetRefIndex, tabClick, setElRefs, projList, projData , userInfo} = useContext(MainContext);
    const auth = useAuthUser();

    let todayTask = []
    let laterTask = []
    let managerProject = []
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let currentWeekNumber = Math.ceil(days / 7);
    let currentDate2 = new Date();
    currentDate2.setHours(17,0)

    //   define todayTask, weektask, latertasks .............
    const [offPostContent, setOffPostContent] = useState("Remarks")
    const [openToday, setOpenToday] = useState(true);
    const [openLater, setOpenLater] = useState(true);

    for (let i=0; i < projList.length; i++ )
    {
        if (userInfo.name === projList[i][4])
        {
            managerProject.push(i)
        }

        console.log("projDataprojDataprojData")
        console.log(projData)
        console.log(userInfo.name)

        if (projData.length > 0)
        {
            let tableRows = projData[i].filter(row => row[2] === userInfo.name)
            for (let j=0; j < tableRows.length; j++ )
            {
                let taskDate = new Date(tableRows[j][4])
                let taskDate2 = new Date(tableRows[j][4])
                if (taskDate2 <= currentDate2 && tableRows[j][8] < 100)
                {
                    todayTask.push({"projectId": i , "taskId": j})
                }else
                {
                    laterTask.push({"projectId": i , "taskId": j})
                }
            }
        }
    }

    let slidesShow = 3
    if (managerProject.length === 1){
        slidesShow = 1
    }else if (managerProject.length === 2) {
        slidesShow = 2
    }

    let options = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 5000,
        slidesToShow: slidesShow,
        slidesToScroll: 1,
        pauseOnHover: true,
        // cssEase: "linear",
    };


    const dataType = tabsData()[0];
    // scrole for todays, upcoming, later
    const scrollEffect = (projectId) => {
        let Row = dataType.filter(row => row.label === projList[projectId][5])
        let index = Row[0].index;
        let items = projList.filter(row => row[5] === projList[projectId][5])
        let type_index = items.findIndex(row => row[2] === projList[projectId][2] )
        setTargetRefIndex(type_index)
        const arrLength = items.length;
        setElRefs((elRefs) =>
            Array(arrLength)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
        );
        tabClick(index,projList[projectId][5])
    }

    // tabs for right side box inside Calendar
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0 || newValue === 1){
            setSelectedStaff(auth().user_record[0])
            staffOffInformation (auth().user_record[0])
        }
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    // staff Information

    let groupStaff = []
    let isDisabled = true;
    if (userInfo.manager === "Yes"){
        groupStaff = Staff.filter(row => row[1] === userInfo.group)
    }else{
        isDisabled = false;
    }

    const [selectedDates, setSelectedDates] = useState();
    const [selectedOffsiteDates, setSelectedOffsiteDates] = useState();
    const [selectedStaff, setSelectedStaff] = useState(auth().user_record[0]);
    const [CheckinDateTime, setCheckinDateTime] = useState("")
    const [DateTime, setDateTime] = useState("")
    const [CheckoutDateTime, setCheckoutDateTime] = useState("")
    const [offSitePost, setOffSitePost] = useState("Remark")
    const [checkinPost, setCheckinPost] = useState("Remark")
    const [absenceDates, setAbsenceDates] = useState([])
    let [excludeDate, nonFullDate] = TimeCalculate(selectedStaff);
    const holiDays = tabsData()[2];

    const isWeekday = (date) => {
        const day = date.getDay();
        let UTCDate = (convertLocalToUTCDate(date)).toString();
        let doesExist  = holiDays.includes(UTCDate)
        let interval = excludeDate.filter(item =>  item.start <= date && date <=  item.end)
        let include = true
        if (interval.length >= 1){
            include = false
        }

        return day !== 4 && day !== 5 && !doesExist && include;
    };

    function convertLocalToUTCDate(date) {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    let waitingOffDateList = []
    for (let i=0; i < waitingOffDate.length; i++){
        waitingOffDateList.push(waitingOffDate[i].date);
    }

    const highlightWithRanges = [
        {
            "react-datepicker__day--highlighted-custom-1": confirmedOffDate,
        },
        {
            "react-datepicker__day--highlighted-custom-2": declinedOffDate,
        },
        {
            "react-datepicker__day--highlighted": waitingOffDateList,
        },
    ];


    const onChangeDate = (dates) => {
        setSelectedDates(dates);
    }


    const onChangeOffsiteDate= (dates) => {
        setSelectedOffsiteDates(dates);

    }

    const sendOffMention = async (e) => {
        e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        let date0 = format(selectedDates, 'yyyy-MM-dd kk:mm')
        try {
            const response = await axios.post(auth().website + "/OffRequest",
                [userInfo.name, userInfo.group, date0, offPostContent , "Request"]
            );
            let key = {index:"",date: date0 , status:"" }
            let waiting = [...waitingOffDate, key]
            setWaitingOffDate(waiting)

            setSnackContent("Confirmation: Your off request registered successfully!")
            setSnackOpen(true);
        } catch (err) {
            setSnackContent("Warning: There was a problem in registering your off request!")
            setSnackOpen(true);
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }


    const cancelOff = async (e) => {
        e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        console.log("cancelOffcancelOff")

        let date0 = format(selectedDates, 'yyyy-MM-dd')
        console.log(date0)
        console.log(waitingOffDateList)
        if (new Date() < selectedDates )
        {
            try {
                const response = await axios.post(auth().website + "/OffRequest",
                    [userInfo.name, userInfo.group,  date0, offPostContent , "Cancel"]
                );
                // let key = {index:"",date: format(selectedDates, 'yyyy-MM-dd') , status:"" }
                // let waiting = [...waitingOffDate, key]

                if (confirmedOffDate.includes(date0))
                {
                    let index = confirmedOffDate.indexOf(date0);
                    let data = [...confirmedOffDate]
                    data.splice(index, 1);
                    setConfirmedOffDate(data)

                }else if (declinedOffDate.includes(date0))
                {
                    let index = declinedOffDate.indexOf(date0);
                    let data = [...declinedOffDate]
                    data.splice(index, 1);
                    setDeclinedOffDate(data)

                }else if (waitingOffDateList.includes(date0)){
                    let index = waitingOffDateList.indexOf(date0);
                    let data = [...waitingOffDate]
                    data.splice(index, 1);
                    setWaitingOffDate(data)
                }
                setSnackContent("Confirmation: Your off request cancelled successfully!")
                setSnackOpen(true);
            } catch (err) {
                setSnackContent("Warning: There was a problem in cancelling your off request!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            setSnackContent("Warning: the selected date can not be calcelled!")
            setSnackOpen(true);
        }
    }


    function totalMinutes(time) {
        let index = (time).indexOf(":");
        let hours = parseInt((time).substring(0, index));
        let minutes = parseInt( (time).substring(index + 1, (time).length));
        let totalMinutes = hours * 60 + minutes;
        return totalMinutes;
    }


    function staffOffInformation (staffName) {

        const month = new Date().toLocaleString('default', { month: 'long' });
        let act = "false"
        if (staffName === auth().user_record[0]) {
            act = "true"
        }

        async function fetchData() {
            try {
                const response = await axios.post(auth().website + "/GetStaffOff",
                    [staffName, auth().user_record[1], month, act]
                );

                let data0 = (response.data)[0]
                let data1 = (response.data)[1]

                let confirmed = []
                let nonConfirmed = []
                let absence = []
                let wait = []
                let visit = []

                for (let i=0; i< data0.length; i++) {
                    let item = data0[i]
                    if (item[2] === "Yes") {
                        confirmed.push(item[1])
                    } else if (item[2] === "No") {
                        nonConfirmed.push(item[1])
                    } else if (item[2] === "absence") {
                        absence.push(item[1])
                    } else {
                        let key = {index: item[0], date: item[1], status: item[2]}
                        wait.push(key)
                    }
                }
                setConfirmedOffDate(confirmed)
                setDeclinedOffDate(nonConfirmed)
                setAbsenceDates(absence)
                setWaitingOffDate(wait)

                for (let i=0; i< data1.length; i++) {
                    let item = data1[i]
                    let key = { date: item[1], message:item[2] };
                    visit.push(key)
                }
                setOffSiteDates(visit);

                if (staffName === auth().user_record[0])
                {
                    let data2 = (response.data)[2]
                    //setTimeSheet(data2)
                    let totalMonthTime = 0
                    let data3 = []
                    for (let i=0; i < data2.length; i++)
                    {
                        if (data2[i][3] !== null )
                        {
                            data3.push({
                                date: data2[i][1],
                                holidayName: data2[i][2].toString() + " - " + data2[i][3].toString()
                            })
                            totalMonthTime += totalMinutes(data2[i][3]) - totalMinutes(data2[i][2]);
                        }else
                        {
                            data3.push({
                                date: data2[i][1],
                                holidayName: data2[i][2].toString()
                            })
                        }
                    }
                    if (0 < totalMonthTime)
                    {
                        let minutes2 = (totalMonthTime) % 60;
                        let hours2 = Math.floor((totalMonthTime ) / 60);
                        setTotalHour(hours2.toString() + ":" +  minutes2.toString());

                        let temp0 = totalMonthTime - 160 * 60
                        if (temp0 > 0) {
                            let minutes0 = Math.abs(temp0) % 60;
                            let hours0 = Math.floor((temp0 ) / 60);
                            setOvertime(hours0.toString() + ":" +  minutes0.toString())
                        }else{
                            setOvertime("0:0")
                        }

                    }
                    setChecksInput(data3)
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData()
    }

    useEffect( () => {
        staffOffInformation (auth().user_record[0])
    }, []);


    const StaffChange = (event) => {
        event.preventDefault()
        setError("");
        setSelectedStaff(event.target.value);
        staffOffInformation(event.target.value)
    }


    const handleOffRequest = async (event, index) => {
        let data = [...waitingOffDate]
        let ind = data[index].index
        try {
            const response = await axios.post(auth().website + "/OffRequestUpdate",
                [ind,  auth().user_record[1], event.target.value]
            );
            if (event.target.value === "Yes"){
                let data0 = [...confirmedOffDate]
                data0.push(data[index].date)
                setConfirmedOffDate(data0)
            }else{
                let data0 = [...declinedOffDate]
                data0.push(data[index].date)
                setDeclinedOffDate(data0)
            }
            data.splice(index, 1);
            setWaitingOffDate(data)

        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    };


    const managerMention = async (e, action) => {
        let date0 = format(selectedOffsiteDates, 'yyyy-MM-dd kk:mm');
        //let time
        try {
            const response = await axios.post(auth().website + "/OffSitePlan",
                [selectedStaff,auth().user_record[1], date0, offSitePost, action]
            );

            setSnackContent("Confirmation: record was saved successfully!")
            setSnackOpen(true);

            if (action === "offsite" || action === "trip"){
                let key = { date: date0, message: offSitePost };
                setOffSiteDates([...offSiteDates, key]);
            }else{
                setAbsenceDates([...absenceDates, date0]);
            }


        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }


    const ChangeDateTime = (date) => {
        setDateTime(date)
        let index0 = checksInput.findIndex(item => item.date === format(date, 'yyyy-MM-dd') )
        if (0 <= index0)
        {
            setCheckoutBtn(false)
            let date0 = checksInput[index0].date;
            let str = checksInput[index0].holidayName;
            let index1 = (str).indexOf("-");
            let startDate = ""
            let endDate = ""
            if ( 0 <= index1)
            {
                startDate = (str).substring(0, index1 -1);
                endDate = (str).substring(index1 + 2, (str).length);
                setCheckoutDateTime(date0 + " " + endDate);
            }else
            {
                startDate = str;
                setCheckoutDateTime(" ");
            }
            setCheckinDateTime(date0 + " " + startDate);
        }else{
            setCheckoutBtn(true)
            setCheckinDateTime(" ");
            setCheckoutDateTime(" ");

        }
    }

    const checkinRecord = async(e) =>
    {
        e.preventDefault()
        setError("");

        let time = (DateTime.getHours()).toString() + ":" + (DateTime.getMinutes()).toString()
        let checkinDate = format(DateTime, 'yyyy-MM-dd')
        const month = DateTime.toLocaleString('default', { month: 'long'});
        setCheckinDateTime(format(DateTime, 'yyyy-MM-dd kk:mm'));

        let act = "No"
        let index0 = checksInput.findIndex(item => item.date === format(DateTime, 'yyyy-MM-dd'))
        if (0 <= index0)
        {
            act = "Yes"
        }

        try
        {
            const response = await axios.post(auth().website + "/CheckinRecord",
                [userInfo.name, checkinDate, time, "in", month, act]
            );
           checkInOutTable(checkinDate,time, "in")
        } catch (err)
        {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }

    const checkoutRecord = async(e) => {
        e.preventDefault()
        setError("");
        let time = (DateTime.getHours()).toString() + ":" + (DateTime.getMinutes()).toString()
        let checkoutDate = format(DateTime, 'yyyy-MM-dd')
        const month = DateTime.toLocaleString('default', { month: 'long' });
        setCheckoutDateTime(format(DateTime, 'yyyy-MM-dd kk:mm'));

         try {
            const response = await axios.post(auth().website +  "/CheckinRecord",
                [userInfo.name, checkoutDate, time, "out", month, "Yes"]
            );
            checkInOutTable(checkoutDate,time, "out")
        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }

    return(
        <Grid container>
            <Grid xs = {8}>
                <Box
                    sx = {{
                        height: 'calc(100vh - 30px)',
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem", ml:2, mt: 2,
                        borderRadius: 5,
                        backgroundColor: "white",
                    }}
                    //className = "Calendar"
                >
                    <Box
                        sx={{ p:2 }}
                    >
                        <h3 className= "headerClass"  > {pageTitle} </h3>
                    </Box>
                    <Box sx = {{p: 2}}>
                        <Grid container className = "profile1">
                            <Grid xs = {8}>
                                <Box  className = "profile" sx = {{width: "100%", textAlign: "left"}}>
                                    <Box sx={{pl: 3,pt: 3, pb: 3}}>
                                        <Typography variant="subtitle1" sx={{fontWeight: 700, color: "black",lineHeight: 0.5}}>
                                            {userInfo.name}
                                        </Typography>
                                        <Typography variant="caption" sx={{fontWeight: 400, color: "black", }}>
                                            {userInfo.position}
                                        </Typography><br />
                                        <Typography  variant="caption" sx={{color: "#031830", }} > Find all information per each project related to you, here. </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid xs = {3} >
                                <Box className = "profile">
                                    <Avatar src={require("../../assets/Management/Picture1.png")}  variant = "rounded"
                                            className= "avatarD"
                                              />
                                </Box>
                            </Grid>
                            <Grid xs = {1}  >
                                <Box className = "profile" >

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx = {{
                            height: "100%",
                            overflowY: "scroll",
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: "1.2rem",
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                        }}
                    >
                        <Box
                            sx={{
                                mt: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                ml: 2,
                                mr:2,
                                display: (managerProject.length) >= 1 ? "block" : "none"
                            }}
                        >
                            <Typography variant="subtitle1" sx={{fontWeight: 700, color: "#031830",lineHeight: 0.5}}>
                                Project manager
                            </Typography>
                            <Box
                                sx={{mr:2, ml: 2}}
                            >
                                <Slider {...options} >
                                    {managerProject.map((rowsData, ind) => (

                                        <Box
                                            key={ind}
                                            component="div"
                                            sx={{ justifyContent: "center", mt: 3, }}
                                        >
                                            <Card
                                                sx={{
                                                    backgroundColor: "#f5f5f6",
                                                    //width: 1 / 2,

                                                    borderRadius: "10px",
                                                    mr: "5px",
                                                    ml: "5px",

                                                }}
                                            >
                                                <CardContent>
                                                    <Box sx = {{width: "100%"}}>
                                                        <Typography variant="caption" sx = {{display: "inline-block", mr: 1, fontWeight: 700, color: "#031830"}} >   Title: </Typography>
                                                        <Button
                                                            className = "myTasks"
                                                            onClick={(event) => scrollEffect (rowsData)}
                                                            sx = {{textTransform: "unset", width: "80%", margin: 0,lineHeight: 1.0, padding: 0,}}
                                                        >
                                                            <textarea
                                                                defaultValue= {projList[rowsData][2]}
                                                                className= "Title"
                                                                style = {{display: "inline-block", color: "#2196f3", mt: "5px"}}
                                                            />
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="caption" sx = {{display: "inline-block", mr: 1, fontWeight: 700, color: "#031830"}} >   Customer:  </Typography>
                                                        <Typography variant="caption" sx = {{display: "inline-block" , color: "#031830"}} >  {projList[rowsData][3]} </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="caption" sx = {{display: "inline-block", mr: 1, fontWeight: 700, color: "#031830"}} >   Priority:  </Typography>
                                                        <Typography variant="caption" sx = {{display: "inline-block" , color: "#031830"}} >  {projList[rowsData][10]} </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="caption" sx = {{display: "inline-block", mr: 1, fontWeight: 700, color: "#031830"}} >   Progress:  </Typography>
                                                        <Typography variant="caption" sx = {{display: "inline-block" , color: "#031830"}} >  {projList[rowsData][9]} </Typography>
                                                    </Box>

                                                </CardContent>
                                            </Card>
                                        </Box>
                                    ))}
                                </Slider>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                mt: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                ml: 2
                            }}
                            id = "userProfile"
                        >
                            <Typography variant="subtitle1" sx={{fontWeight: 700, color: "#031830",lineHeight: 0.5}}>
                                To do list
                            </Typography>
                            <Box
                                component="div"
                                sx={{ justifyContent: "center", mt: 3, }}
                            >
                                <Paper sx={{width: '100%', mb: 3}}>
                                    <Box variant="subtitle1">
                                        <Divider id = "divid" textAlign="left" className={openToday ? "divider": undefined}   >
                                            <IconButton
                                                onClick={() => {setOpenToday(!openToday);}}
                                                aria-label="expand"
                                                //size="small"
                                                className="header"
                                            >
                                                {openToday ?  <ArrowDropDownRounded/>
                                                    :<ArrowRightRounded/> }
                                            </IconButton>
                                            <Typography
                                                className="header"
                                                variant="caption"

                                            >
                                                Today
                                            </Typography>

                                        </Divider>

                                    </Box>
                                    <Box sx={{pl: 5,mr:5 }}>
                                        <Collapse in={openToday} timeout="auto" unmountOnExit>
                                            <TableContainer  sx={{ml : 4}}  >
                                                <Table className = "tableContainer">
                                                    <TableBody>
                                                        <TaskTable
                                                            table_rows={todayTask}
                                                            type = "task"
                                                            scrollEffect = {scrollEffect}
                                                        />
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Collapse>
                                    </Box>
                                </Paper>

                                <Paper sx={{width: '100%', mb: 3}}>
                                    <Divider id = "divid" textAlign="left" className={openLater ? "divider": undefined}  >
                                        <Box variant="subtitle1" >
                                            <IconButton
                                                onClick={() => {setOpenLater(!openLater);}}
                                                aria-label="expand"
                                                //size="small"
                                                className="header"
                                            >
                                                {openLater ? <ArrowDropDownRounded/>
                                                    :<ArrowRightRounded/> }
                                            </IconButton>
                                            <Typography variant="caption"
                                                        className="header"
                                            >
                                                Later
                                            </Typography>
                                        </Box>
                                    </Divider>
                                    <Box sx={{pl: 5, mr:5 }}>

                                        <Collapse in={openLater} timeout="auto" unmountOnExit>
                                            <TableContainer  sx={{ml : 4}}>
                                                <Table className = "tableContainer">
                                                    <TableBody>
                                                        <TaskTable
                                                            table_rows={laterTask}
                                                            type = "task"
                                                            scrollEffect = {scrollEffect}
                                                        />
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Collapse>

                                    </Box>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid xs = {4}
                  id = "calender"
            >
                <Box
                    sx = {{
                        height: 'calc(100vh - 30px)',
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem", ml:2, mr:1, mt: 2,
                        borderRadius: 5,
                        backgroundColor: "white"
                    }}
                    //className = "Calendar"
                >
                    <Box sx={{ p:2 }}  >
                        <h3 className= "headerClass"  > Calendar </h3>
                    </Box>
                    <Box
                        sx = {{
                            height: "100%",
                            overflowY: "scroll",
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: "1.2rem",
                            padding: 2,
                        }}
                    >
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                aria-label="full width tabs example"
                                centered
                            >
                                <Tab  label="Staff" {...a11yProps(0)} />
                                <Tab  disabled={!isDisabled} label="Manager"  {...a11yProps(1)} />
                                <Tab  label="Performance" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}

                        >
                            <TabPanel value={value} index={0} dir={theme.direction} className = "dashbaord2"
                                      sx ={{pt: 4}}
                            >
                                <Box sx = {{pt: 3, mt: 3, textAlign:"center"}} >
                                    <Box className = "datapickerD">
                                        <DatePicker
                                            selected={selectedDates}
                                            onChange={onChangeDate}
                                            highlightDates={highlightWithRanges}
                                            showTimeSelect
                                            excludeDates={offSiteDates}
                                            timeIntervals={5}
                                            timeFormat="HH:mm"
                                            //timeCaption="time"
                                            filterDate = {isWeekday}
                                            holidays={nonFullDate}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            inline
                                        />


                                        <textarea
                                            name="postContent"
                                            //defaultValue={"Please record your request for off day!"}
                                            value={offPostContent}
                                            onChange={e => setOffPostContent(e.target.value)} //
                                        />
                                    </Box>

                                    <Box sx={{ml: "10px", mt: 2, pb: 1}} >
                                        <Button
                                            className="editBtn"
                                            onClick=  {(event) => sendOffMention(event)}
                                            sx={{p: "12px", backgroundColor: "#153250"}}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            className="editBtn"
                                            onClick=  {(event) => cancelOff(event)}
                                            sx={{p: "12px", backgroundColor: "#153250"}}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>

                                <Box sx={{ mt:3 }}  >
                                    <h4 className= "headerClass"  > Schedule </h4>
                                </Box>

                                <Box sx={{pl:2, mt:1}}>
                                    <Typography variant = "caption" sx={{color: "#031830"}} > <FiberManualRecordRounded sx ={{color: "#add3d0"}} /> Confirmed Days-off </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#a92420b0"}} /> Declined Requests </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#ff6803ad"}} /> Waiting Requests </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#f4d8009c"}} /> Off-site Activities </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#aed690ab"}} /> Partially Full </Typography> <br />
                                </Box>
                            </TabPanel>

                            <TabPanel value={value} index={1} dir={theme.direction} className = "dashbaord2">
                                <Box id = "search" sx = {{textAlign: "center", mb: 2,  mt: 3, justifyContent: "center"}}>
                                    <Paper
                                        component="form"
                                        sx={{width:"98%", textAlign: "center", }}
                                    >
                                        <select
                                            value = {selectedStaff}
                                            onChange=  {(event) => StaffChange(event)}
                                            className="selectOption"
                                            defaultValue={selectedStaff}
                                        >
                                            {groupStaff.map((staff) => (
                                                <option key={staff[0]} value={staff[0]}>
                                                    {staff[0]}
                                                </option>
                                            ))}
                                        </select>
                                    </Paper>
                                </Box>

                                <Box sx = {{p: 0, mt: 2, textAlign:"center"}} className = "datapickerD">
                                    <DatePicker
                                        selected={selectedOffsiteDates}
                                        onChange={onChangeOffsiteDate}
                                        highlightDates={highlightWithRanges}
                                        showTimeSelect
                                        excludeDates={offSiteDates}
                                        timeIntervals={5}
                                        timeFormat="HH:mm"
                                        filterDate = {isWeekday}
                                        holidays = {nonFullDate}
                                        dateFormat = "MM/dd/yyyy HH:mm"
                                        inline
                                    />
                                    <textarea
                                        name="postContent"
                                        value={offSitePost} // ...force the input's value to match the state variable...
                                        onChange={e => setOffSitePost(e.target.value)} //
                                    />
                                    <br/>
                                    <Box sx={{ml: "10px", mt: 2, pb: 1}}>
                                        <Button
                                            className="editBtn"
                                            onClick=  {(event) => managerMention(event, "offsite")}
                                            sx={{backgroundColor: "#153250"}}
                                        >
                                            Offsite
                                        </Button>
                                        <Button
                                            className="editBtn"
                                            onClick=  {(event) => managerMention(event, "trip")}
                                            sx={{p: "12px", backgroundColor: "#153250"}}
                                        >
                                            Trip
                                        </Button>
                                        <Button
                                            className="editBtn"
                                            onClick=  {(event) => managerMention(event, "absence")}
                                            sx={{p: "12px", backgroundColor: "#153250"}}
                                        >
                                            Absence
                                        </Button>
                                    </Box>
                                </Box>

                                <Box sx={{ mt:3 }}  >
                                    <h4 className= "headerClass"  > Schedule </h4>
                                </Box>

                                <Box sx={{pl:2, mt:1}}>
                                    <Typography variant = "caption" sx={{color: "#031830"}} > <FiberManualRecordRounded sx ={{color: "#add3d0"}} /> Confirmed Days-off </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#a92420b0"}} /> Declined Requests </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#ff6803ad"}} /> Waiting Requests </Typography> <br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#f4d8009c"}} /> Off-site Activities </Typography><br />
                                    <Typography variant = "caption" sx={{color: "#031830"}}> <FiberManualRecordRounded sx ={{color: "#aed690ab"}} /> Partially Full </Typography> <br />
                                </Box>

                                <Box sx = {{ mb: 2, mt: 3}}>
                                    {/*<Typography variant="subtitle1" sx={{fontWeight: 700, color: "#031830",lineHeight: 0.5}}>*/}
                                    {/*    Waiting off request*/}
                                    {/*</Typography>*/}
                                    <h5 className= "headerClass"  >  Waiting off request </h5>

                                    <Box sx = {{ mt: 2, ml: 1}}>
                                        {waitingOffDate.length > 0 ?
                                            waitingOffDate.map((item, key) => {
                                                return (
                                                    <Grid container
                                                       key = {key}
                                                    >
                                                        <Grid xs = {6}>
                                                            <Box sx={{pl:3, color: "#031830"}}>
                                                                <Typography variant = "caption"> {item.date} </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs = {6} sx={ {textAlign: "right"}}>
                                                            <FormControl id = "offDays">
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    disabled
                                                                    value={waitingOffDate[key].status}
                                                                    onChange= {(event) => handleOffRequest(event, key)}
                                                                >
                                                                    <FormControlLabel value="Yes" control={<Radio />} size = "small" id ="success"  label="Yes" />
                                                                    <FormControlLabel value="No" control={<Radio />} size = "small"  id ="error" label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                            :
                                            <Box sx={{pl:5, mt:1}}>
                                                <Box sx = {{textAlign: "left",}}>
                                                    <Typography  variant="caption" sx={{color: "#031830"}} > No Off request! </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>

                            </TabPanel>

                            <TabPanel value={value} index={2} dir={theme.direction} className = "dashbaord2">
                                <Box sx = {{pt: 3, mt: 3, textAlign:"center"}} >
                                    <Box className = "datapickerD">

                                        <DatePicker
                                            selected={DateTime}
                                            onChange={ChangeDateTime}
                                            showTimeSelect
                                            timeIntervals={5}
                                            holidays={checksInput}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            inline
                                        />
                                    </Box>

                                </Box>
                                <Box
                                >
                                    <textarea
                                        name="checkinContent"
                                        value={checkinPost} // ...force the input's value to match the state variable...
                                        onChange={e => setCheckinPost(e.target.value)} //
                                    />
                                    <br/>

                                    <Grid container>
                                        <Grid xs={6}>

                                            <Box sx={{ml: "10px", mt: 2, pb: 1, float: "right"}}>
                                                <Button
                                                    className="editBtn"
                                                    onClick= {(event) => checkinRecord(event)}
                                                    sx={{p: "12px", backgroundColor: "#153250"}}
                                                >
                                                    Checkin
                                                </Button>
                                                <br/>
                                                <Typography variant="caption" sx={{color: "#031830", textAlign: "center"}}>  {CheckinDateTime}  </Typography>
                                            </Box>

                                        </Grid>
                                        <Grid xs={6}>

                                            <Box sx={{ml: "10px", mt: 2, pb: 1, float: "left"}}>
                                                <Button
                                                    className="editBtn"
                                                    onClick= {(event) => checkoutRecord(event)}
                                                    sx={{p: "12px", backgroundColor: "#153250"}}
                                                    disabled={checkoutBtn}
                                                >
                                                    Checkout
                                                </Button>
                                                <br/>
                                                <Typography variant="caption" sx={{color: "#031830", textAlign: "center"}}>  {CheckoutDateTime}  </Typography>
                                            </Box>

                                        </Grid>
                                    </Grid>

                                </Box>

                                <Box sx = {{ mb: 2, mt: 5}}>
                                    <h5 className= "headerClass"  > Total Hours </h5>

                                    <Box sx={{pl:5, mt:1}}>
                                        <Box sx = {{textAlign: "left",}}>
                                            <Typography  variant="caption" sx={{color: "#031830"}} > {totalHours} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx = {{ mb: 2, mt: 3}}>
                                    <h5 className= "headerClass"  > Overtime </h5>
                                    <Box sx={{pl:5, mt:1}}>
                                        <Box sx = {{textAlign: "left",}}>
                                            <Typography  variant="caption" sx={{color: "#031830"}} > {overTime} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </SwipeableViews>
                    </Box>
                </Box>

            </Grid>

        </Grid>
    );
}
export default Dashboard;
