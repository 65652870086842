import React from "react";
import ReactDOM from "react-dom/client";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "react-auth-kit";
// React Slick Css Files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";

const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
                <AuthProvider
                    authType={"cookie"}
                    authName={"_auth"}
                    cookieDomain={window.location.hostname}
                    cookieSecure={false}
                >
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                </AuthProvider>
    </React.StrictMode>
);



