import React from "react";
import { useState, useContext} from "react";
import MainContext from "../../context";

export function InitTasks({Cat}) {
    const { Category, projData, projList } = useContext(MainContext);
    let tasks = []
    let temp = 0
    let allCat = []
    if (Cat === "All"){
        allCat = Category
    }else{
        allCat = [Cat]
    }
    for ( let i=0; i < allCat.length; i++ )
    {
        let typeProject = projList.filter(row => row[5] === allCat[i] && row[6] !== "")
        let projectIndexes = []
        for (let j=0; j < typeProject.length; j++ )
        {
            projectIndexes.push(projList.findIndex(row => row[1] === typeProject[j][1] ))
        }

        if (typeProject.length > 0)
        {
            let dateArr = []
            let endDateArr = []
            for ( let j=0; j < typeProject.length; j++ )
            {
                if (typeProject[j][6] !== "")
                {
                    dateArr.push(new Date(typeProject[j][6]).getTime());
                }
                if (typeProject[j][7] !== "")
                {
                    endDateArr.push(new Date(typeProject[j][7]).getTime());
                }
            }

            let startDate= Math.min(...dateArr);
            let ind = dateArr.indexOf(startDate);
            let endDate= Math.max(...endDateArr);
            let ind2 = endDateArr.indexOf(endDate);

            if (Cat === "All")
            {
                let key = {
                    start: new Date(typeProject[ind][6]),
                    end:  new Date(typeProject[ind2][7]),
                    name: allCat[i],
                    id: "Cat" + i.toString(),
                    progress: 0,
                    type: "milestone",
                    hideChildren: false,
                    styles : { progressColor: '#adbb9c', progressSelectedColor: '#ff9e0d', backgroundColor : "#a85858" }
                }
                tasks.push(key)
            }

            for ( let j=0; j < typeProject.length; j++ )
            {
                let progressIndex = 0
                let primaryProgress = 0
                let projectHours = 0
                let index = projectIndexes[j];
                let progressValue = 0
                if (projData[index].length > 0)
                {
                    for (let k=0; k < projData[index].length; k++ ){
                        progressValue += projData[index][k][8] * projData[index][k][12]
                    }
                }
                let key = {
                    start:new Date(projList[index][6]),
                    end: new Date(projList[index][7]),
                    name: projList[index][2],
                    id: i.toString() + "Project " + j.toString(),
                    //progress: progressValue,
                    progress: 0,
                    type: "project",
                    hideChildren: false,
                    project: "Cat" + i.toString(),
                    styles : { progressColor: '#2a647b', progressSelectedColor: "#2a647b", backgroundColor : "#2e8fa5", backgroundSelectedColor : "#2e8fa5" }

                }

                tasks.push(key)
                progressIndex = tasks.length - 1



                for (let k=0; k < projData[index].length; k++ )
                {

                    if (projData[index][k][1] !== ""){
                        let progressColor = "#ffcbba"
                        let progressSelectedColor = '#ffcbba'
                        let backgroundColor = '#feece3'
                        primaryProgress += parseInt(projData[index][k][8]) * parseInt(projData[index][k][6])
                        projectHours += parseInt(projData[index][k][6])
                        let key = {
                            start: new Date(projData[index][k][11]),
                            end: new Date(projData[index][k][4]),
                            name: projData[index][k][1] + " - " + (projData[index][k][8]).toString() + " %",
                            id: temp ,
                            progress: projData[index][k][8],
                            //dependencies: ["Task 1"],
                            type: "task",
                            project: i.toString() + "Project " + j.toString(),
                            styles : { progressColor: progressColor, progressSelectedColor: progressSelectedColor, backgroundColor : backgroundColor, backgroundSelectedColor: backgroundColor }
                        }

                        temp = temp + 1;

                        tasks.push(key)

                    }
                }
                tasks[progressIndex]["progress"] = Math.floor(primaryProgress / projectHours)
                tasks[progressIndex]["name"] += " - " + (tasks[progressIndex]["progress"]).toString() + " %"
            }
        }
    }

    return tasks;
}





export function GetStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}
