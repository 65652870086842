import {Box, Avatar, Typography, Button} from "@mui/material"
import {AccountCircleOutlined, ExitToApp, PowerSettingsNewTwoTone} from "@mui/icons-material";
import MainContext from "../../context";
import {grey, red} from "@mui/material/colors";
import {useAuthUser} from 'react-auth-kit';
import {useSignOut} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useState} from "react";
import Fade from '@mui/material/Fade';

const SidebarHeader = () => {
    const auth = useAuthUser()
    const signOut = useSignOut();
    const navigate = useNavigate();
    const logOut = () =>{
        signOut();
        navigate("/");
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        signOut();
        navigate("/");

    };
    const handleProfile = () =>{
        setAnchorEl(null);
        //signOut();
        //navigate("/");
    }
    const anchorClose= () =>{
        setAnchorEl(null);
    }

    return (
        <>
            {/*<Box sx = {{position: "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)", }}>*/}
            <Box sx = {{justifyContent: "center",  m: 2 }}>
                <Box>
                    <img
                        src={require("../../assets/logo0.png")}
                        alt="SCA"
                        className="footerImg"
                    />
                </Box>
                {/*<Typography variant="caption" sx={{color: "whitesmoke", fontSize: "0.5rem", mt: -2}}> Project Management </Typography>*/}

            </Box>
            {/*</Box>*/}
            {/*<Box sx = {{textAlign: "left", ml: "15px" }}>*/}
            {/*    <Box*/}
            {/*        sx={{ ml: "15px"}}>*/}
            {/*        <Tooltip title="Account settings">*/}
            {/*            <IconButton*/}
            {/*                onClick={handleClick}*/}
            {/*                size="small"*/}
            {/*                sx={{ ml: 2 }}*/}
            {/*                aria-controls={open ? 'account-menu' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                aria-expanded={open ? 'true' : undefined}*/}
            {/*            >*/}
            {/*                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>*/}
            {/*            </IconButton>*/}
            {/*        </Tooltip>*/}

            {/*        <Menu*/}
            {/*            id="fade-menu"*/}
            {/*            MenuListProps={{*/}
            {/*                'aria-labelledby': 'fade-button',*/}
            {/*            }}*/}
            {/*            anchorEl={anchorEl}*/}
            {/*            open={open}*/}
            {/*            onClose={anchorClose}*/}
            {/*            TransitionComponent={Fade}*/}
            {/*        >*/}
            {/*            <MenuItem onClick={handleProfile}>*/}
            {/*                <ListItemIcon>*/}
            {/*                    <PersonAdd fontSize="small" />*/}
            {/*                </ListItemIcon>*/}

            {/*                Profile*/}
            {/*            </MenuItem>*/}
            {/*            /!*<MenuItem onClick={handleClose}>My account</MenuItem>*!/*/}
            {/*            <MenuItem onClick={handleClose}>*/}
            {/*                <ListItemIcon>*/}
            {/*                    <Logout fontSize="small" />*/}
            {/*                </ListItemIcon>*/}
            {/*                Logout*/}
            {/*            </MenuItem>*/}
            {/*        </Menu>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
        </>

    );
}
export default SidebarHeader;