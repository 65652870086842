import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {Button} from "@mui/material";
const SidebarContain = ({children}) => {
    return (
        <Grid
            // xs={0}
            // sm={0}
            // md={2}
            // lg={2}
            // xl={2}
            sx={{ backgroundColor: "#031830" ,
                height: "100vh",
                //overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
            }}
            className = "sidbarWidth"
        >
            {children}
        </Grid>
    );
};

export default SidebarContain;