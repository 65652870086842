import { useState, useEffect, createRef } from "react";
import MainLayout from "../layouts/MainLayout";
import { Sidebar } from "../components/sidebar";
import PagesContainer from "./PagesContainer";
import SidebarContainer from "./SidebarContain";
import MainContext from "../context";
import DrawerActionButton from "../components/Drawer/DrawerActionButton";
import {MainPage, Test, Test2, MentionDrawer, TaskPage, Search, ManagementDashboard, ManagementReport, Performance} from "../pages/projmngmt/index";
import axios, {AxiosError} from "axios";
import { Typography } from "@mui/material";
import {useAuthUser} from 'react-auth-kit';
import { tabsData } from "../components/data/tabsData.sidebar";
import Page from "../pages/projmngmt/Page";
import SwipeableViews from "react-swipeable-views";
import {useContext} from "react";
import { format } from 'date-fns';
import {abs} from "stylis";

function createArray(N) {
    return Array.from({length: N}, (_, index) => index + 1);
}




const TaskManagement = () => {
    const [openModal, setOpenModal] = useState(false);

    const [ items, setItems] = useState(["United States","India","Germany"]);
    const arrLength = items.length;
    const [elRefs, setElRefs] = useState([]);
    const [targetRefIndex, setTargetRefIndex] = useState("");
    const [userInfo, setUserfInfo] = useState({
        name: "",
        position: "",
        group:"",
        manager:"",
        mangergroup:"",
        admin:"",
    });
    const data = tabsData()[1];

    const [projData, setProjData] = useState([]);
    const [projList, setProjList] =  useState([]);
    const auth = useAuthUser()
    const headCells = [{"id": 0 , "label": 'ID', "width": 20}, {"id": 1 , "label": 'Task', "width": 400} , {"id": 2 ,"label": 'Owner', "width": 150}, {"id": 3 ,"label": 'Priority', "width": 100}, {"id": 4,"label": 'Estimated H', "width":100}, {"id": 5,"label": 'Start date', "width": 150 }, {"id": 6,"label": 'Due date', "width":150 }, {"id": 7 ,"label": 'Status', "width": 80 },  {"id": 8 ,"label": 'Actual H', "width":80}, {"id": 9 ,"label": 'Progress %', "width":130}, {"id": 10 ,"label": 'Dependency', "width": 100}];
    const Category = ["DOA - Airworthiness", "DOA - ISM", "DOA - Projects", "DOA - Proposals", "POA - Quality System", "POA - Projects", "R & D"];
    const [Staff, setStaff ] =useState([])
   // const [openSearch, setOpenSearch] = useState({});
    const [open, setOpen] = useState({});
    let newDate = new Date()
    const currentDate = newDate.toISOString().split('T')[0];

    useEffect(() => {
        setError("");
        async function fetchData() {
            try {
                const response = await axios.post(auth().website + "/GetProjectData",[]);
                setStaff(response.data[0])
                setProjList(response.data[1]);
                let arr = {}
                for (let i=0; i < response.data[1].length; i++)
                {
                    arr[i] = true
                }
                setOpen(arr);

                let tasksData = []
                let pretask = 0
                console.log("tasksData")
                for (let i= 0; i < (response.data[1]).length; i++ )
                {
                    pretask = 0
                    let continues = true;
                    let projectTask = (response.data.splice(2))[i]
                    tasksData.push([])
                    while (continues)
                    {
                        let index0 = projectTask.findIndex(item => item[12] === pretask)
                        if (index0 !== -1)
                        {
                            tasksData[i].push(projectTask[index0])
                            console.log("index" , index0)
                            console.log("pretaskp" , pretask)

                            pretask = projectTask[index0][0];


                        }else
                        {
                            continues = false
                            break;
                        }
                    }
                }

                console.log(tasksData)
                setProjData(tasksData);
                //setProjData(response.data.splice(2));
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData();

        setUserfInfo({
            ["name"]: auth().user_record[0],
            ["position"]: auth().user_record[2],
            ["group"]:auth().user_record[1],
            ["manager"]:auth().user_record[3],
            ["mangergroup"]:auth().user_record[4],
            ["admin"]:auth().user_record[5]
        })
    }, []);

    const [projName, setProjName] = useState();
    const [error, setError] = useState("");

    const [temp, setTemp] = useState({
        index:"",
        number:"",
        title: "",
        customer: "",
        manager: "",
        priority: "",
        type: "",
    });
    const setProjectData = (event) => {
        setTemp({...temp,[event.target.name]: event.target.value, });
    }

    const projectInfoUpdate = async (e) => {
        e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        let Tvalue = ["",temp.number, temp.title,temp.customer, temp.manager, temp.type, "", "","", 0, temp.priority]
        try {
            const response = await axios.post(auth().website + "/UpdateProjectInfo",
                Tvalue
            );
            if (response.data !== "Project updated")
            {
                Tvalue[1] = response.data;
                setProjList([...projList, Tvalue]);
                const newdata = [["", "", "","", "", "",0, 0, 0, "", auth().user,"",0]]
                setProjData([...projData, newdata]);
            }else{
                const data = [...projList]
                data[temp.index][2] = Tvalue[2]
                data[temp.index][3] = Tvalue[3]
                data[temp.index][4] = Tvalue[4]
                data[temp.index][10] = Tvalue[10]
                setProjList(data)
            }
            setSnackContent("Confirmation: Project information was saved successfully!")
            setSnackOpen(true);
        } catch (err) {
            //setSnackContent("Warning: Project information was not saved!")
            if (err && err instanceof AxiosError)
                setSnackContent(err.response?.data.message);
            else if (err && err instanceof Error) setSnackContent(err.message);
            setSnackOpen(true);
        }
        setOpenModal(false);
    }

    const projectInfoUpdate2 = async () => {
        //e.preventDefault()
        setError("");
        let tablekey = deleteInfo.projectId;
        setProjectAnchorEl(null);
        try {
            const response = await axios.post(auth().website + "/UpdateProjectInfo",
                projList[tablekey]
            );
            if (response.data !== "Project updated")
            {
                let data3 = [...projList];
                data3[tablekey][1] =  (response.data).toString();
                setProjList(data3);
            }
            setSnackContent("Confirmation: The project has been successfully saved!")
            setSnackOpen(true);
        } catch (err) {
            setSnackContent("Warning: The saving of the project failed!")
            setSnackOpen(true);
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }

    }

    // const deleteProject2 = async (tableIndex) => {
    //     //e.preventDefault()
    //     setError("");
    //     setProjectAnchorEl(null);
    //     if (projList[tableIndex][0] !== "")
    //     {
    //         try {
    //             const response = await axios.post(auth().website + "/DeleatProject",
    //                 [projList[tableIndex][1]]
    //             );
    //             if (response.data === "Group Delete"){
    //                 let reducedArr = [...projList];
    //                 reducedArr.splice(tableIndex, 1);
    //                 setProjList(reducedArr)
    //                 let reducedArr2 = [...projData];
    //                 reducedArr2.splice(tableIndex, 1);
    //                 setProjData(reducedArr2)
    //                 setSnackContent("Confirmation: The project has been successfully deleted!")
    //                 setSnackOpen(true);
    //             }else{
    //                 setSnackContent("Warning: The project deletion failed!")
    //                 setSnackOpen(true);
    //                 setError("Warning: The project deletion failed!")
    //             }
    //         } catch (err) {
    //             if (err && err instanceof AxiosError)
    //                 setError(err.response?.data.message);
    //             else if (err && err instanceof Error) setError(err.message);
    //         }
    //     }
    //     else
    //     {
    //         let reducedArr = [...projList];
    //         reducedArr.splice(tableIndex, 1);
    //         setProjList(reducedArr)
    //         let reducedArr2 = [...projData];
    //         reducedArr2.splice(tableIndex, 1);
    //         setProjData(reducedArr2)
    //     }
    //
    // }

    const deleteProject = async (e) => {
        e.preventDefault()
        setError("");
        let tableIndex = temp.index;
        setProjectAnchorEl(null);
        if (projList[tableIndex][0] !== "")
        {
            try {
                const response = await axios.post(auth().website + "/DeleatProject",
                    [projList[tableIndex][1]]
                );
                if (response.data === "Group Delete"){
                    let reducedArr = [...projList];
                    reducedArr.splice(tableIndex, 1);
                    setProjList(reducedArr)
                    let reducedArr2 = [...projData];
                    reducedArr2.splice(tableIndex, 1);
                    setProjData(reducedArr2)
                    setSnackContent("Project was deleted successfully!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Project was not deleted!")
                    setSnackOpen(true);
                    setError("Warning: Group is not deleted!")
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            let reducedArr = [...projList];
            reducedArr.splice(tableIndex, 1);
            setProjList(reducedArr)
            let reducedArr2 = [...projData];
            reducedArr2.splice(tableIndex, 1);
            setProjData(reducedArr2)
        }
        setOpenModal(false);
    }


    const StaffHours = (staff, startDate, endDate) => {
        //const {projData, projList,} = useContext(MainContext);
        //let projectIndexes = []
        let totalHours = 0
        let intervalhours = 0
        const currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let start0 = "";
        let end0 = "";
        let limitedHours = "";
        if (startDate !== "" && endDate !== ""){
            start0 = new Date(startDate).getTime();
            end0 = new Date(endDate).getTime();
            limitedHours = (Math.ceil((Math.abs(start0- end0) + 1) / (1000 * 3600 * 24))) * 8.5;
        }

        for (let j=0; j < projList.length; j++ )
        {
            let table = []
            table = projData[j].filter(row => row[2] === staff)
            if (table.length > 0)
            {
                if ((table.length ===1 && table[0][11] === "") || (table.length ===1 && table[0][4] === "")){
                    totalHours = 0
                }else{
                    for (let k=0; k < table.length; k++ )
                    {
                        let date0 = new Date(table[k][11]);
                        let date1 = new Date(table[k][4]);
                        let month0 = date0.getMonth();
                        let day0 = date0.getDate();
                        let month1 = date1.getMonth();
                        let day1 = date1.getDate();
                        if (month0 !== month1){
                            if (currentMonth === month0)
                            {
                                totalHours += ((30 - day0 + 1) * parseInt(table[k][6])) / (day1 + (30 - day0) + 1)
                            } else if (currentMonth === month1)
                            {
                                totalHours += ((day1) * parseInt(table[k][6])) / (day1 + (30 - day0) + 1)
                            }
                        } else {
                            totalHours += parseInt(table[k][6]);
                        }
                        if (startDate !== "" && endDate !== ""){
                            let start = date0.getTime();
                            let end = date1.getTime();
                            let timeDiff = Math.abs(start- end) + 1;
                            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            let hoursPerDay = parseInt(table[k][6]) / diffDays ;
                            let timeDiff0 = 0
                            let diffDays0 = 0
                            if (start <= start0 && end0 <= end){
                                timeDiff0 = Math.abs(end0- start0) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start <= start0 && start0 <= end  && end <= end0){
                                timeDiff0 = Math.abs(end- start0) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start0 <= start && start <= end0 && end0 <= end){
                                timeDiff0 = Math.abs(end0- start) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }else if (start0 <= start && end <= end0){
                                timeDiff0 = Math.abs(end- start) + 1;
                                diffDays0 = Math.ceil(timeDiff0 / (1000 * 3600 * 24));
                                intervalhours += hoursPerDay * diffDays0;
                            }
                        }
                    }
                }
            }
        }
        return [totalHours, intervalhours, limitedHours]
    }


    const editTask = async(tablekey, tablerow, e) => {
        e.preventDefault()
        setError("");
        setAnchorEl(null);
        let updatedData = [projList[tablekey][1], projData[tablekey][tablerow], currentDate.toString(), auth().user]
        try {
            const response = await axios.post(auth().website + "/UpdateProject",
                updatedData
            );
            if (response.data !== "Task updated"){
                let data2 = [...projData]
                data2[tablekey][tablerow][0] = response.data;
                setProjData(data2);
            }
            setSnackContent("Confirmation: The project task has been successfully saved!!")
            setSnackOpen(true);
        } catch (err) {
            setSnackContent("Warning: Project task was not saved!")
            setSnackOpen(true);
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);
        }
    }

    const [changeID, setChangeID] = useState([])

    const editTask2 = async() => {
        //e.preventDefault()
        setError("");
        setAnchorEl(null);
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;

        if (projData[tablekey][tablerow][5] !== "Critical"){
            let updatedData = [projList[tablekey][1], projData[tablekey][tablerow], currentDate.toString(), auth().user, clickedRow]
            try {
                const response = await axios.post(auth().website + "/UpdateProject",
                    updatedData
                );

                if ((response.data)[0] !== "Task Updated")
                {
                    let data2 = [...projData]
                    data2[tablekey][tablerow][0] = (response.data)[0];

                    if (clickedRow !== ""){
                        data2[tablekey][clickedRow][12] = (response.data)[0];
                    }
                    setProjData(data2);
                }

                let data3 = [...projList]
                data3[tablekey][6] = (response.data)[1];
                data3[tablekey][7] = (response.data)[2];
                setProjList(data3);

                setSnackContent("Confirmation: The project task has been successfully saved!!")
                setSnackOpen(true);

            } catch (err) {
                setSnackContent("Warning: The saving of the project task failed!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            let projectArr = []
            let taskArr = []
            for (let j=0; j < changeID.length; j++ )
            {
                let tablekey0 = changeID[j][0]
                let tablerow0 = changeID[j][1]
                projectArr.push(projList[tablekey0][1])
                taskArr.push(projData[tablekey0][tablerow0])
            }
            let updatedData = [projectArr, taskArr, currentDate.toString(), auth().user, clickedRow]
            try {
                const response = await axios.post(auth().website + "/UpdateAllProject",
                    updatedData
                );

                if (response.data !== "Task updated"){
                    let data2 = [...projData]
                    data2[tablekey][tablerow][0] = response.data;
                    if (clickedRow !== ""){
                        data2[tablekey][clickedRow][12] = (response.data)[0];
                    }
                    setProjData(data2);
                }
                setSnackContent("Confirmation: The project task has been successfully saved!!")
                setSnackOpen(true);

            } catch (err) {
                setSnackContent("Warning: The saving of the project task failed!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
    }

    // const deleteTask2 = async(tablekey, tablerow) => {
    //     setError("");
    //     setAnchorEl(null);
    //
    //     if (projData[tablekey][tablerow][0] !== ""){
    //         let updatedData = [projList[tablekey][1], projData[tablekey][tablerow][0]]
    //         try {
    //             const response = await axios.post(auth().website +  "/RemoveTask",
    //                 updatedData
    //             );
    //             if (response.data == "Task Delete")
    //             {
    //                 let reducedArr = [...projData];
    //                 reducedArr[tablekey].splice(tablerow, 1);
    //                 setProjData(reducedArr)
    //                 setSnackContent("Confirmation: The project task has been successfully deleted!")
    //                 setSnackOpen(true);
    //             }
    //             else
    //             {
    //                 setSnackContent("Warning: The project task deletion failed!")
    //                 setSnackOpen(true);
    //                 setError("Warning: The project task deletion failed!")
    //             }
    //         } catch (err) {
    //             if (err && err instanceof AxiosError)
    //                 setError(err.response?.data.message);
    //             else if (err && err instanceof Error) setError(err.message);
    //         }
    //     }else{
    //         let reducedArr = [...projData];
    //         reducedArr[tablekey].splice(tablerow, 1);
    //         setProjData(reducedArr)
    //         setSnackContent("Confirmation: The project task has been successfully deleted!")
    //         setSnackOpen(true);
    //     }
    //
    // }

    const deleteTask = async(tablekey, tablerow, e) => {
        e.preventDefault()
        setError("");
        setAnchorEl(null);
        if (projData[tablekey][tablerow][0] !== ""){
            let updatedData = [projList[tablekey][1], projData[tablekey][tablerow][0]]
            try {
                const response = await axios.post(auth().website + "/RemoveTask",
                    updatedData
                );
                if (response.data == "Task Delete"){
                    let reducedArr = [...projData];
                    reducedArr[tablekey].splice(tablerow, 1);
                    setProjData(reducedArr)
                    setSnackContent("Confirmation: The project task has been successfully deleted!")
                    setSnackOpen(true);
                }
                else{
                    setSnackContent("Warning: The project task deletion failed!")
                    setSnackOpen(true);
                    setError("Warning: The project task deletion failed!")
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            let reducedArr = [...projData];
            reducedArr[tablekey].splice(tablerow, 1);
            setProjData(reducedArr)
            setSnackContent("Confirmation: The project task has been successfully deleted!")
            setSnackOpen(true);
        }
    }

    const onValUpdate = (projNum, tableRow, rowColumn, event) => {
        const data = [...projData];
        switch (event.target.name)
        {
            case "progressbar":
                data[projNum][tableRow][rowColumn] = parseInt(event.target.value);
                if (parseInt(event.target.value)  === 100){
                    data[projNum][tableRow][3] = "Done";
                }
                break;
            case "status":
                if (event.target.value === "Done"){
                    data[projNum][tableRow][8] = 100;
                }
                data[projNum][tableRow][rowColumn] = event.target.value;
                break;
            case "estimatedHours":
                data[projNum][tableRow][11] = "";
                data[projNum][tableRow][4] = "";
                data[projNum][tableRow][rowColumn] = event.target.value;
                break;

           default:
                data[projNum][tableRow][rowColumn] = event.target.value;
                break;
        };
        setProjData(data);
    }

    const [mentionOption, setMentionOption] = useState({
        table:"",
        row: "",
    });

    const [alertOpen, setAlertOpen] = useState(false);
    const [mentionOpen, setMentionOpen] = useState(false)
    const makeMention = () => {
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;
        setMentionOption({...mentionOption,["table"]: tablekey,["row"]: tablerow,});
        setMentionOpen(true)
        setAnchorEl(null);

    }
    const [selected, setSelected] = useState([]);
    const [postContent, setPostContent] = useState("");
  //  const [searchPage, setSearchPage] = useState(false);
    //

    const sendMention = async(e) => {
        e.preventDefault()
        setError("");
        let postAccouts = []
        let tablekey = deleteInfo.projectId;
        let tablerow = deleteInfo.taskId;

        let taskName = projData[tablekey][tablerow][1];
        let projectName = projList[tablekey][2];
        let postSubject = "Task '" + taskName.toString() + "' of project '" + projectName.toString() + "' is updated!";

        for (let i=0; i < selected.length; i++ ){
            postAccouts.push(selected[i].value)
        }
        if (selected && postContent ){
            try {
                const response = await axios.post(auth().website + "/sendPost",
                    [postAccouts, postContent, postSubject]
                );
                if (response.data === "postContent was sent"){
                    setSnackContent("Confirmation: The notification has been sent successfully!")
                    setSnackOpen(true);
                }
                else{
                    setSnackContent("Warning: The notification failed to send!")
                    setSnackOpen(true);
                    setError("Warning: The notification failed to send!")
                }
            } catch (err) {
                setSnackContent("Warning: The notification failed to send!")
                setSnackOpen(true);
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            setError("Warning: No account has been selected or there is no notification content available for sending.")
        }
        setMentionOpen(false)
    }

    const statusOptions = [
        { label: "Done", value: 1 },
        { label: "Working on it", value: 2 },
        { label: "Stuck", value: 3 },
        { label: "Not started", value: 4 }
    ];

    const [pageNumber, setPageNumber] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [type, setType] = useState("1")

    const projectType = ["DOA - Airworthiness", "DOA - ISM", "DOA - Projects", "DOA - Proposals", "POA - Quality System", "POA - Projects", "R & D" ]
    const handlePageNumber = (event, newPage) => {
        setPageNumber(newPage);
        if ((1 <= newPage) && (newPage <= 7)){
            setType(projectType[newPage - 1]);
        }

    };

    const setProjectInfo = (keys, index, event) =>{
        let data3 = [...projList];
        data3[keys][index] =  event.target.value;
        setProjList(data3);
    }

    const tabClick = (index, projType) =>
    {
        if (index < 8 && 0 < index)
        {
            let items = projList.filter(row => row[5] === projType)
            let arrLength = items.length;

            setElRefs((elRefs) =>
                Array(arrLength)
                    .fill()
                    .map((_, i) => elRefs[i] || createRef()),
            );
        }
        let data4 = [...projList];
        let arr = {}
        for (let i=0; i < data4.length; i++)
        {
            if (index === 0){
                arr[i] = true;
            }else{
                arr[i] = true;
            }
        }
        setOpen(arr);

        setDrawerOpen(false);
        setPageNumber(index);
        setType(projType)
        // if ((1 <= index) && (index <= 7)){
        //     setType(projectType[index - 1]);
        // }
        //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [projectAnchorEl, setProjectAnchorEl] = useState(null);


    const [deleteInfo, setDeleteInfo] = useState({
        projectId: "",
        taskId: "",
        staffId: "",
    });


    // const handleDialogOpen = () => {
    //     if (deleteInfo.taskId === -1) {
    //         //setDeleteInfo({["projectId"]: key1 , ["taskId"]: index})
    //         setDialogOpen(true);
    //         setProjectAnchorEl(null);
    //     }else
    //     {
    //         setDialogOpen(true);
    //         setAnchorEl(null);
    //     }
    // };

    // const handleDisagree = () => {
    //     setDialogOpen(false);
    // };
    // const handleAgree = () => {
    //     if (deleteInfo.taskId === -1){
    //         deleteProject2(deleteInfo.projectId)
    //     }else{
    //         deleteTask2(deleteInfo.projectId, deleteInfo.taskId)
    //     }
    //     setDialogOpen(false);
    // };

    const [snackContent, setSnackContent] = useState("")
    const [snackOpen, setSnackOpen] = useState(false);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const TemporaryProject = () =>{
        setOpenModal(true);
    }

    const [confirmedOffDate, setConfirmedOffDate] = useState([]);
    const [declinedOffDate, setDeclinedOffDate] = useState([]);
    const [waitingOffDate, setWaitingOffDate] = useState([]);
    const [offSiteDates, setOffSiteDates] = useState([]);
    const [timeSheet, setTimeSheet] = useState([])
    const [totalHours, setTotalHour] = useState(0)
    const [overTime, setOvertime] = useState(0)
    const [checkinBtn, setCheckinBtn] = useState(false)
    const [checkoutBtn, setCheckoutBtn] = useState(false)
    const [checksInput, setChecksInput] = useState([])
    const [checkinDate, setCheckinDate ] = useState(new Date())

    function totalMinutes(time) {
        let index = (time).indexOf(":");
        let hours = parseInt((time).substring(0, index));
        let minutes = parseInt( (time).substring(index + 1, (time).length));
        let totalMinutes = hours * 60 + minutes;
        return totalMinutes;
    }

    function setTimeData(index0, time1, time2, act){

        let differenceTime = 0
        if (act === 0){
            let str = checksInput[index0].holidayName
            let index1 = (str).indexOf("-");
            let startDate = (str).substring(0, index1 -1);
            let endDate = (str).substring(index1 + 2, (str).length);
            differenceTime = totalMinutes(endDate) - totalMinutes(startDate)
        }

        let totalMonthTime = totalMinutes(time2) -  totalMinutes(time1) + (totalMinutes(totalHours) - differenceTime );

        let minutes = (totalMonthTime) % 60;
        let hours = Math.floor((totalMonthTime ) / 60);

        setTotalHour(hours.toString() + ":" +  minutes.toString());

        let temp0 = totalMonthTime - 160 * 60
        let minutes0 = Math.abs(temp0) % 60;
        let hours0 = Math.floor((temp0 ) / 60);
        setOvertime(hours0.toString() + ":" +  minutes0.toString())


    }

    function convertLocalToUTCDate(date) {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    
    const checkInOutTable = (Date, time, temp) => {
        let data = [...checksInput]
        if (temp === "in")
        {
            let index0 = data.findIndex(item => item.date === Date)
            if (0 <= index0)
            {
                let index1 = (data[index0].holidayName).indexOf("-");
                if ( 0 <= index1){
                    let str = data[index0].holidayName
                    let enddate = (str).substring(index1 + 2, (str).length);
                    setTimeData(index0, time, enddate, 0)
                    data[index0].holidayName = time.toString() + " - " + enddate
                }else{
                    data[index0].holidayName = time.toString()
                }

            }else{
                data.push({
                    date: Date,
                    holidayName: time.toString()
                })
            }
            //setCheckinBtn(true)
            setChecksInput(data)
        }
        else if (temp === "out")
        {
            let index0 = data.findIndex(item => item.date === Date)
            if (0 <= index0)
            {
                let str = data[index0].holidayName;
                let index1 = (str).indexOf("-");
                let startDate = ""
                if ( 0 <= index1)
                {
                    startDate = (str).substring(0, index1 -1);
                    setTimeData(index0, startDate, time, 0 )
                }else
                {
                    startDate = str;
                    setTimeData(index0, startDate, time, 1)
                }
                data[index0].holidayName = startDate + " - " + time.toString()
                //setCheckoutBtn(true)
            }
            setChecksInput(data)
        }
    }

    // const [excludeDate, setExcludeDate] = useState([]);
    // const [nonFullDate, setNonFullDate] = useState([]);
    // const [startDates, setStartDates] = useState([]);
    // const [endDates, setEndDates] = useState([]);
    //
    // const [excludeDate0, setExcludeDate0] = useState([]);
    // const [nonFullDate0, setNonFullDate0] = useState([]);
    // const [startDates0, setStartDates0] = useState([]);
    // const [endDates0, setEndDates0] = useState([]);
    // const [projtaskID, setProjtaskID] = useState([]);

    const [clickedRow, setClickedRow ] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const [criticalItem, setCriticalItem] = useState(false);
    const [highItem, setHighItem] = useState(false);
    const [lowItem, setLowItem] = useState(false);
    const [dialogOpenTime, setDialogOpenTime] = useState(false);
    const [ dedicatedRow, setDedicatedRow ] = useState(["",""]);
    return (
        <MainContext.Provider
            value={{
                setError, projectInfoUpdate, projName, setProjName, editTask, deleteTask, projData, setProjData, projList, setProjList,
               deleteProject, onValUpdate, currentDate, mentionOption, setMentionOption, makeMention,mentionOpen, setMentionOpen,
                selected, setSelected, postContent, setPostContent,sendMention, statusOptions, temp, setTemp, setProjectData,
                handlePageNumber, pageNumber, drawerOpen, setDrawerOpen, type, setType, setProjectInfo
                , headCells, Category,open, setOpen,tabClick, Staff, setStaff, alertOpen, setAlertOpen, anchorEl, setAnchorEl,
                 projectAnchorEl, setProjectAnchorEl, handleSnackClose, snackOpen, setSnackOpen,snackContent, setSnackContent,  editTask2,
                deleteInfo, setDeleteInfo, projectInfoUpdate2, elRefs, setElRefs, targetRefIndex, setTargetRefIndex, userInfo, setUserfInfo
                , openModal, setOpenModal, TemporaryProject, confirmedOffDate, setConfirmedOffDate, declinedOffDate, setDeclinedOffDate, waitingOffDate, setWaitingOffDate, offSiteDates, setOffSiteDates,
                timeSheet, setTimeSheet, totalHours, setTotalHour, overTime, setOvertime,checkInOutTable, checksInput, checkinBtn, checkoutBtn, checkinDate, setCheckinDate,changeID, setChangeID, setChecksInput, setCheckoutBtn, clickedRow, setClickedRow,
                //excludeDate, setExcludeDate, nonFullDate, setNonFullDate, startDates, setStartDates, endDates, setEndDates, excludeDate0, setExcludeDate0, nonFullDate0, setNonFullDate0, startDates0, setStartDates0,endDates0, setEndDates0, projtaskID, setProjtaskID,
               // handleAgree, handleDialogOpen, handleDisagree, deleteTask2, deleteProject2,
                dialogOpen, setDialogOpen, dialogContent, setDialogContent, criticalItem, setCriticalItem, highItem, setHighItem, lowItem, setLowItem, dialogOpenTime, setDialogOpenTime, dedicatedRow, setDedicatedRow,
            }}

        >
            <MainLayout>
                <SidebarContainer>
                    <Sidebar/>
                </SidebarContainer>
                <PagesContainer>
                    <MainPage />
                </PagesContainer>
                <MentionDrawer />
            </MainLayout>
        </MainContext.Provider>
    );
}

export default TaskManagement;

