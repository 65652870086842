import {createContext, useState} from "react";
export default createContext ({
    result: false,
    imgSrc: "Single",
    drawerOpen : false,
    setDrawerOpen: () => {},
    drawerOpenJoint: false,
    setDrawerOpenJoint: () => {},
    getDentinfo: "",
    handleDentInfo: () => {},
    runDent: () => {},
    getOutput:"",
    NewProject: () => {},
    SaveProject:() => {},
    LoadProject: () => {},
    getJointinfo:"",
    setJointInfo:() => {},
    getJointMaterial:"",
    runJoint:() => {},
    getPlottype:"",
    handlePlotType:() => {},
    plotData: "",
    LoadJointProject:() => {},
    NewJointProject:() => {},
    SaveJointProject:() => {},
    BacktoMain: () => {},
    BacktoMainDent: () => {},
    pageNumber:"",
    handlePageNumber: () => {},
    dentInputError:"",
    setDentInputError: () => {},
    jointInputError:"",
    setJointInputError: () => {},
    Description:"",
    alertOpen: false,
    setAlertOpen: () => {},
    jointAlertOpen: false,
    setJointAlertOpen: () => {},
    guides: true,
    setGuides: () => {},
    fullGuideLine: () => {},
    projectInfoUpdate: () => {},
    projName:"",
    setProjName: () => {},
    editTask: () => {},
    deleteTask: () => {},
    projData: [],
    setProjData: () => {},
    projList: [],
    setProjList: () => {},
    deleteProject: () => {},
    onValUpdate: () => {},
    currentDate: "",
    mentionOption:"",
    setMentionOption: () => {},
    makeMention: () => {},
    mentionOpen:"",
    setMentionOpen: () => {},
    selected:[],
    setSelected: () => {},
    postContent:"",
    setPostContent: () => {},
    sendMention: () => {},
    statusOptions: [],
    type:"",
    setType: () => {},
    setProjectInfo: () => {},
    headCells:[],
    Category:[],
    open:"",
    setOpen: () => {},
    tabClick: () => {},
    Staff: [],
    setStaff: () => {},
    anchorEl:"",
    setAnchorEl: () => {},
    dialogOpen:"",
    setDialogOpen: () => {},
    //handleDialogOpen: () => {},
    //handleDisagree: () => {},
    //handleAgree: () => {},
    projectAnchorEl:"",
    setProjectAnchorEl: () => {},
    snackOpen:"",
    setSnackOpen: () => {},
    setSnackContent: () => {},
    handleSnackClose: () => {},
    snackContent:"",
    //deleteTask2: () => {},
    //deleteProject2: () => {},
    editTask2: () => {},
    deleteInfo:"",
    setDeleteInfo: () => {},
    projectInfoUpdate2: () => {},
    dialogContent:"",
    setDialogContent: () => {},
    elRefs:"",
    setElRefs: () => {},
    targetRefIndex:"",
    setTargetRefIndex: () => {},
    userInfo:"",
    setUserfInfo: () => {},
    openModal:"",
    setOpenModal: () => {},
    TemporaryProject: () => {},
    setError: () => {},
    confirmedOffDate:[],
    setConfirmedOffDate: () => {},
    declinedOffDate:[],
    setDeclinedOffDate: () => {},
    waitingOffDate:[],
    setWaitingOffDate: () => {},
    offSiteDates:[],
    setOffSiteDates: () => {},
    timeSheet:[],
    setTimeSheet: () => {},
    totalHours:0,
    setTotalHour: () => {},
    overTime:0,
    setOvertime: () => {},
    checkInOutTable: () => {},
    checkinBtn:false,
    checkoutBtn:false,
    checkinDate:new Date(),
    setCheckinDate: () => {},
    checksInput:[],
    temp:{},
    setTemp: () => {},
    setProjectData: () => {},
    changeID: [],
    setChangeID: () => {},
    setChecksInput: () => {},
    setCheckoutBtn: () => {},
    // excludeDate: [],
    // setExcludeDate: () => {},
    // nonFullDate: [],
    // setNonFullDate: () => {},
    // startDates: [],
    // setStartDates: () => {},
    // endDates: [],
    // setEndDates: () => {},
    // excludeDate0: [],
    // setExcludeDate0: () => {},
    // nonFullDate0: [],
    // setNonFullDate0: () => {},
    // startDates0: [],
    // setStartDates0: () => {},
    // endDates0: [],
    // setEndDates0: () => {},
    // projtaskID: [],
    // setProjtaskID: () => {},
    setClickedRow: () => {},
    clickedRow: "",
    criticalItem: false,
    setCriticalItem: () => {},
    highItem: false,
    setHighItem: () => {},
    lowItem: false,
    setLowItem: () => {},
    dialogOpenTime: false,
    setDialogOpenTime: () => {},
    dedicatedRow:[],
    setDedicatedRow: () => {},
})