import React, {useState, useContext, useRef, useEffect} from "react";
import {
    Box, Button,Divider, InputBase, Paper,Typography, IconButton, CircularProgress} from "@mui/material";
import {grey} from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import MainContext from "../../context";
import Axios, {AxiosError} from 'axios';
import {useAuthUser} from "react-auth-kit";
import Grid from "@mui/material/Unstable_Grid2";
import { InitTasks } from "./StaffChart";
import { tabsData } from "../../components/data/tabsData.sidebar";
import axios from 'axios';
import {PDFFile} from "./index";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
//import ImagePicker from "./ImagePicker";


function convertUTCToLocalDate(date)
{
    if (!date) {
        return date
    }
    let dateType = new Date(date);
    let Hour = dateType.getUTCHours();
    let Minute = dateType.getUTCMinutes();
    //let Hour = dateType.getHours();
    //let Minute = dateType.getMinutes();

    date = new Date(dateType.getUTCFullYear(), dateType.getUTCMonth(), dateType.getUTCDate())
    date.setHours(Hour,Minute)
    return date
}


const WorkPaper = ({pageTitle}) =>
{
    const auth = useAuthUser();
    const [ipDetails, setIpDetails] = useState([]);
    const {Staff, userInfo, projList, projData, setError} = useContext(MainContext);
    const data = tabsData()[1];

    const [searchContent, setSearchContent] = useState("");
    const staffHourse = useState(InitTasks()[1]);
    let userIndex = Staff.findIndex(item => item === userInfo.name);
    let userHourse = staffHourse[0][userIndex];

    // output from another function
    const monthHours = 160.0;
    let overTime = 0
    if (userHourse){
        overTime = userHourse - monthHours;
    }

    const [staffDetail, setStaffDetail] =useState({
        name: userInfo.name,
        position: userInfo.position,
        group: userInfo.group,
    });

    const [taskHolder, setTaskHolder] = useState(0);
    let projectManager = 0;
    let table0 = projList.filter(row => row[4] === staffDetail.name)
    projectManager = table0.length;
    const [overdue, setOverdue] = useState(0)
    const [progress, setProgress] = useState(0);

    const [OffSiteDays, setOffSiteDays] = useState(0);
    const [OffDays, setOffDays] = useState(0);
    const [absenceDays, setAbsenceDays] = useState(0);
    const [tripDates,setTripDays] = useState(0)

    function staffPerformance (staffName){
        if (projList.length > 0){
            let temp = 0
            let today = new Date()
            let tasks = 0
            for (let j=0; j < projList.length; j++) {
                let tableRows = []
                tableRows = projData[j].filter(row => row[2] === staffName)
                tasks += tableRows.length;
                for (let k = 0; k < tableRows.length; k++) {
                    if ((new Date(tableRows[k][4]) <= today) && (tableRows[k][8] < 100)) {
                        temp += 1
                    }
                }
            }
            setTaskHolder(tasks)
            setOverdue(temp)
            setProgress((temp / taskHolder) * 100)
        }

        const month = new Date().toLocaleString('default', { month: 'long' });
        async function fetchData() {
            try {
                const response = await axios.post(auth().website + "/GetStaffOff",
                    [staffName, staffDetail.group, month, "false"]
                );
                let temp0 = (response.data)[0]
                let temp1 = (response.data)[1]

                let confirmedMinutes = 0
                let absenceMinutes = 0
                for (let i=0; i< temp0.length; i++)
                {
                    let item = temp0[i]
                    let date0 = new Date(item[1])
                    if (item[2] === "Yes"){
                        confirmedMinutes += date0.getMinutes() + date0.getHours() * 60
                    }else if (item[2] === "absence") {
                        absenceMinutes += date0.getMinutes() + date0.getHours() * 60
                    }
                }

                setOffDays((Math.floor((confirmedMinutes ) / (60 * 24))).toString() + "." + (((confirmedMinutes) % (60 * 24)).toString()).substring(0, 2) );
                setAbsenceDays((Math.floor((absenceMinutes ) / (60 * 240))).toString() + "." +  (((absenceMinutes) % (60 * 24)).toString()).substring(0, 2) );

                let offsiteMinutes = 0
                let tripMinutes = 0
                for (let i=0; i < temp1.length; i++)
                {
                    let item = temp1[i]
                    let date0 = new Date(item[1])
                    if (item[3] === "offsite"){
                        offsiteMinutes += date0.getMinutes() + date0.getHours() * 60
                    }else if (item[3] === "trip") {
                        tripMinutes += date0.getMinutes() + date0.getHours() * 60
                    }
                }

                setOffSiteDays((Math.floor((offsiteMinutes ) / (60 * 24))).toString()  + "." +   (((offsiteMinutes) % (60 * 24)).toString()).substring(0, 2));
                setTripDays((Math.floor((tripMinutes ) / (60 *24))).toString()  + "." +  (((tripMinutes) % (60 * 24)).toString()).substring(0, 2));
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData()
    }

    useEffect( () => {
        staffPerformance(staffDetail.name)
    }, []);

    const PerformanceMention = (event) => {
        Axios.get('https://api.ipify.org/?format=json').then((res) => {
            setIpDetails(res.data.ip);
        });
        // const res = await axios.get("https://api.ipify.org/?format=json");
        // console.log(res.data);
        // setIP(res.data.ip);
    }

    const SearchStaff= () => {
        let Detail = Staff.filter(row => row[0] === searchContent)
        if (Detail.length >= 1){
            setStaffDetail({["name"]: Detail[0][0] ,["position"]: Detail[0][2]})
        }
        staffPerformance(Detail[0][0])
    }
  // const itemsEls = useRef(new Array())
    const itemEls = useRef({})
    const inputRef = useRef();

    const handleClick = () => {
        inputRef.current.focus();
    };
    // const GetPerformance = () => {
    //     ReactPDF.render(<BasicDocument />, `example.pdf`);
    //     console.log(userInfo.name)
    // }

    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                    <Typography  variant="caption" sx={{color: "#031830"}} > Here is the overview of your performance in this month. </Typography>
                    <Box sx = {{textAlign: "right", display: "inline-block", float: "right" }} id = "search">
                        <Paper
                            component="form"

                            sx={{  display: 'flex', alignItems: 'left', width:250, textAlign: "left", direction: "ltr", overflow: "hidden"}}
                        >
                            <InputBase
                                sx={{ ml: 1, pl:2, flex: 1, fontSize: "0.85rem", textAlign: "left" }}
                                placeholder="Staff"
                                inputProps={{ 'aria-label': 'search google maps' }}
                                value={searchContent}
                                onChange={e => setSearchContent(e.target.value)}
                            />
                            <Divider sx={{ height: 15, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '0px', color: "#62606087", pr: 2}} aria-label="search"
                                        onClick={(event) => SearchStaff()}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </Box>
                </Box>

                <Box
                    sx = {{
                        height: "100%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        mb: 2, ml:4, mr:4,
                    }}
                >
                    <Box sx = {{width: "100%", mt: 7, textAlign: "center"}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 700, color: "#031830",lineHeight: 0.5}}>
                            {staffDetail.name}
                        </Typography>
                        <Typography variant="caption" sx={{fontWeight: 400, color: "#031830"}}>
                            {staffDetail.position}
                        </Typography>
                        <Divider variant="middle"  sx = {{border: "2px solid gray", mt: 1}} />
                    </Box>
                    <Grid container  sx = {{mt : 2 }}>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Project manager
                                </Typography>
                                <Typography variant="caption" className="performanceHead2" >
                                    Total project as a manager
                                </Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" >
                                        {projectManager}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Task holder
                                </Typography>
                                <Typography variant="caption" className="performanceHead2">
                                    Total tasks in a month
                                </Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3">
                                        {taskHolder}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Off-site activity days
                                </Typography>
                                <Typography variant="caption" className="performanceHead2"></Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" sx = {{display: 'inline-block'}}  >
                                        {OffSiteDays}
                                    </Typography>
                                    {/*<Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.5rem", color: "#031830", pl: 1 }}  >*/}
                                    {/*    hr*/}
                                    {/*</Typography>*/}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Overtime
                                </Typography>
                                <Typography variant="caption" className="performanceHead2"></Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" sx = {{display: 'inline-block'}} >
                                        {overTime}
                                    </Typography>
                                    <Typography sx = {{display: 'inline-block', fontWeight: 700, fontSize: "1.5rem", color: "#031830", pl: 1 }}  >
                                        hr
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container  sx = {{fontSize: "1.2rem" }} color = {grey[600] }>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Off-days
                                </Typography>
                                <Typography variant="caption" className="performanceHead2">
                                    It will be calculated per month
                                </Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" >
                                        {OffDays}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs= {4}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" sx = {{fontWeight: 700,lineHeight: 0.5,mt:3,pl: 2, color: "#801717"}}>
                                    Overdue tasks
                                </Typography>
                                <Typography variant="caption" className="performanceHead2" ></Typography>
                                <Box sx={{textAlign: "center" }}>

                                    <Box sx = {{fontWeight: 700, fontSize: "2.75rem", color: "#801717", display: 'inline-block', mt: 2 }} >
                                        <Typography sx = {{fontWeight: 700, fontSize: "2.75rem", color: "#801717",  }} >
                                            {overdue}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ position: 'relative', display: 'inline-block', mr: 10,  float: "right" }}>
                                        { progress > 0 ?
                                            <>
                                                <CircularProgress  variant="determinate" value = {progress}  />
                                                <Box
                                                    sx={{
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Typography variant="caption" component="div" color="#031830">
                                                        {`${Math.round(progress)}%`}
                                                    </Typography>
                                                </Box>
                                            </>
                                       : null }
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid xs= {2}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Trips days
                                </Typography>
                                <Typography variant="caption" className="performanceHead2"></Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" >
                                        {tripDates}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box className="performanceBox">
                                <Typography variant="subtitle2" className="performanceHead1">
                                    Absence days
                                </Typography>
                                <Typography variant="caption" className="performanceHead2"></Typography>
                                <Box sx={{textAlign: "center" }}>
                                    <Typography className="performanceHead3" >
                                        {absenceDays}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <PDFDownloadLink
                        document={<PDFFile
                            staffDetail = {staffDetail}
                            projectManager = {projectManager}
                            taskHolder = {taskHolder}
                            offsiteActivities = {OffSiteDays}
                            Overtime = {overTime}
                            offDays = {OffDays}
                            Overdue = {overdue}
                            Trips = {tripDates}
                            Absence = {absenceDays}
                        />}

                        filename="FORM"
                    >
                        {({loading}) => (loading ?
                            <Box sx={{ml: "10px", mt: 3, pr: 2}} id = "calender">
                                <Button
                                    className="editBtn"
                                    sx={{backgroundColor: "#153250",float: "right"}}
                                >
                                    Loading Document ...
                                </Button>
                            </Box>
                            :
                            <Box sx={{ml: "10px", mt: 3, pr: 2}} id = "calender">
                                <Button
                                    className="editBtn"
                                    sx={{backgroundColor: "#153250",float: "right"}}
                                >
                                 Download performance
                                </Button>
                            </Box>
                            )}
                    </PDFDownloadLink>


                </Box>
            </Box>
        </>
    );
};
export default WorkPaper;