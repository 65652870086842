import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import {GuanttChart, StaffGuanttChart} from "./index"
import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import React, {useContext, useEffect, useState,} from "react";
import {Bar} from "react-chartjs-2";
import {ProjHours} from "./projectHours";
import MainContext from "../../context";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SwipeableViews from "react-swipeable-views";
import axios, {AxiosError} from "axios";
import {useAuthUser} from "react-auth-kit";

Chart.register(CategoryScale);
Chart.register([annotationPlugin], [ChartDataLabels] );

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"

            hidden={value !== index + 1}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index + 1 && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

const ManagementReport = ({pageTitle}) => {

    const [Cat, setCat] = useState("DOA - Projects");
    const { Category , setError} = useContext(MainContext);
    const projectDifferences = ProjHours({Cat});
    const auth = useAuthUser();



    let  actualData = []
    let estimatedData = []
    let projectCode = []

    for (let i=0; i < projectDifferences.length; i++ )
    {
        for (let j =0; j < projectDifferences[i].length; j++){
            estimatedData.push(projectDifferences[i][j].estimHours)
            actualData.push(projectDifferences[i][j].DiffTime + projectDifferences[i][j].estimHours)
            projectCode.push(projectDifferences[i][j].project)
        }
    }




    const onCatChange = (event) =>{
        actualData = []
        estimatedData = []
        projectCode = []
        setCat(event.target.value);

        // catIndex = Category.findIndex(item => item === event.target.value);
        // catGroup = projectDifferences[0][catIndex];
        // for (let i=0; i < catGroup.length; i++ )
        // {
        //     estimatedData.push(catGroup[i].estimHours)
        //     actualData.push(catGroup[i].DiffTime + catGroup[i].estimHours)
        //     projectCode.push(catGroup[i].project)
        // }
    }

    const options = {
       // responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                title: {
                    display: false,
                    text: 'Project Hours'
                },
            },
        },

        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true
            },
            title: {
                display: true,
                text: "Project hours",
                padding: {
                    bottom: 30
                },
                weight: "bold",
                color: "#4e4d4ddb",
                font: {
                    size: 13
                },
                align: "start"
            },
        }
    }

    let data0 ={
        datasets:[{
            barPercentage: 0.5,
            barThickness: 20 | 'flex',
            maxBarThickness: 30,
            minBarLength: 10,
            label: 'Project estimated hours',
            data : estimatedData
        },
        {
            barPercentage: 0.5,
            barThickness: 20,
            maxBarThickness: 30,
            minBarLength: 10,
            label: 'Project actual hours',
            data:  actualData
        }],
        labels: projectCode
    }


    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
                id = "mReport"
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                    <Typography  variant="caption" sx={{color: "#031830"}} > Find all project and staff reports here. </Typography>
                    <Box sx = {{textAlign: "right", display: "inline-block", float: "right" }} id = "search">
                        <Paper
                            component="form"
                            sx={{  display: 'flex', alignItems: 'left', width:250, textAlign: "left", direction: "ltr", }}
                        >
                            <select
                                value={Cat}
                                onChange=  {(event) => onCatChange(event)}
                                className="selectOption"
                            >
                                <option value="">  </option>
                                {Category.map((cat) => (
                                    <option key={cat} value={cat}>
                                        {cat}
                                    </option>
                                ))}
                                <option value="All"> All  </option>
                            </select>
                        </Paper>
                    </Box>
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />


                    <Box
                        sx = {{
                            height: "100%",
                            overflowY: "scroll",
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: "1.2rem",
                            mb: 2, ml:4, mr:4,
                            scrollbarWidth: "none",
                        }}
                    >

                        <SwipeableViews
                            //axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={page - 1}
                            onChangeIndex={handleChange}
                        >
                            <TabPanel value={page} index={0} sx={{backgroundColor: "red"}}>

                                    <Box sx = {{ mt: 5}}>
                                        <GuanttChart Cat = {Cat} />
                                    </Box>

                            </TabPanel>

                            <TabPanel value={page} index={1} sx={{backgroundColor: "red"}}>
                                <Box sx = {{ mt: 5}}>
                                    <StaffGuanttChart  />
                                </Box>
                            </TabPanel>

                            <TabPanel value={page} index={2} sx={{}}>

                                    <Box sx = {{ mt: 5, height: "100%"}}>
                                        <Bar height={140} data={data0} options={options} />
                                    </Box>

                            </TabPanel>
                        </SwipeableViews>
                    </Box>

                <Box sx = {{alignContent: "center", textAlign:"left", justifyContent:"center", mt: 'auto',mb: 3}} >
                    <Stack spacing={3}
                           sx={{alignItems: "center"}}
                    >
                        <Pagination sx= {{color: "black", textAlign: "center"}} count={3} page={page} onChange={handleChange} />
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default ManagementReport;
