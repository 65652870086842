import Grid from "@mui/material/Unstable_Grid2";
import {grey} from "@mui/material/colors";

const PagesContainer = ({ children }) => {
    return (
        <Grid
            // xs={12}
            // sm={12}
            // md={10}
            // lg={10}
            // xl={10}
            sx={{ height: "100vh", overflow: "hidden"}}
            className = "background2"
        >
            {children}
        </Grid>
    );
};

export default PagesContainer;
