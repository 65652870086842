import React from "react";
import {useContext} from "react";
import MainContext from "../../context";

export function ProjHours({Cat}) {
    const { Category, projData, projList } = useContext(MainContext);
    let projectDiffHours = [];

    let allCat = []
    if (Cat === "All"){
        allCat = Category
    }else{
        allCat = [Cat]
    }

    for (let i=0; i < allCat.length; i++)
    {
        let typeProject = projList.filter(row => row[5] === allCat[i])
        let projectIndexes = []
        for (let j = 0; j < typeProject.length; j++)
        {
            projectIndexes.push(projList.findIndex(row => row[1] === typeProject[j][1]))
        }
        let typeProjectDiff = []
        if (typeProject.length > 0){
            for (let j = 0; j < typeProject.length; j++) {
                let index = projectIndexes[j];
                let DiffTime = 0;
                let estimatedTime = 0;
                if (projData[index].length > 0) {
                    for (let k = 0; k < projData[index].length; k++)
                    {
                        if (projData[index][k][7] !== "")
                        {
                            DiffTime += parseInt(projData[index][k][7]) - parseInt(projData[index][k][6]);
                            estimatedTime += parseInt(projData[index][k][6])
                        }
                    }
                    let key = {"project": projList[index][2], "DiffTime": DiffTime, "estimHours": estimatedTime }
                    typeProjectDiff.push(key)
                }
            }
        }
        projectDiffHours.push(typeProjectDiff)
    }

    return projectDiffHours;
}





