import {CopyrightRounded, NoteAddOutlined} from "@mui/icons-material";
import {grey, red} from "@mui/material/colors";
import {Button, Box, Typography} from "@mui/material";
import {
    SettingsOutlined,PowerSettingsNewTwoTone
} from "@mui/icons-material";
import {useContext} from "react";
import MainContext from "../../context";
import {useSignOut} from "react-auth-kit";
import {useNavigate} from "react-router-dom";

const SidebarFooter = () =>{

    const signOut = useSignOut();
    const navigate = useNavigate();
    const logOut = () =>{
        signOut();
        navigate("/");
    }
    return(
        <>
            <Box sx = {{alignContent: "center", textAlign:"left", justifyContent:"center", mt: 'auto',}} >
                <Button
                    className="home btn vertical"
                    component="label"
                    onClick= {logOut}
                >
                    <PowerSettingsNewTwoTone  sx={{ fontSize: "1.75rem", color: grey[500],  }} />
                </Button>
            </Box>
            {/*<Box sx={{textAlign:"left", ml:"10px"}}>*/}
            {/*    <Typography sx={{fontWeight: 700, color: "whitesmoke", textAlign:"left", fontSize: "0.55rem"}}  >*/}
            {/*        Sign Out*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
        </>
    );
}
export default SidebarFooter;
