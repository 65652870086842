import {Box, Button, Divider, Typography, label, textarea} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import {useContext, useState} from "react";
import MainContext from "../../context";
import {grey} from "@mui/material/colors";

const MentionContent = () => {
    const {mentionOption, projData, projList, selected, setSelected, postContent, setPostContent,sendMention} =  useContext(MainContext);

    let taskName = projData[mentionOption.table][mentionOption.row][1];
    let projectName = projList[mentionOption.table][2];

    const options = [
        { label: "@Navid", value: "navid.seyedali@sca-co.com" },
        { label: "@Davoud", value: "d.hassanzadeh@sca-co.com" },
        { label: "@Hossein", value: "h.rashedi@sca-co.com" },
        { label: "@Zahra", value: "z.bayat@sca-co.com" },
        { label: "@Sadegh", value: "s.hassanzadeh@sca-co.com" },
        { label: "@Mitra", value: "m.yadollahi@sca-co.com" },
        { label: "@Hamed", value: "h.aslanian@sca-co.com" },
        { label: "@Mina", value: "m.emami@sca-co.com" },
        { label: "@Faranak", value: "f.seyedghasem@sca-co.com" },
        { label: "@Somayeh", value: "s.soudmand@sca-co.com" },
    ];


    return(
        <>
            <Box
                sx={{ height: "100vh", overflow: "hidden",  justifyContent: "center", backgroundColor: "#ffffff" , pl : 3}}
            >
                {/*<SidebarTabs />*/}
                {mentionOption.table + " / " + mentionOption.row}
                <Box sx = {{mt: "20%"}}>
                   <Typography> Note Information </Typography>
                   <Divider  sx = {{mr: 2, borderColor:grey[400]}} />
                    <Box  sx={ {mt: 2, color: grey[800], fontSize: "0.70rem"}}>
                        <Typography variant="caption" sx = {{color: "#626060", fontWeight: 700}} > Enter your note: </Typography><br />
                        <textarea
                            name="postSubject"
                            defaultValue={"Task '" + taskName.toString() + "' of project '" + projectName.toString() + "' is updated!"}
                            rows={1}
                            cols={61}
                            sx = {{pt:"15px" }}
                            //value={postContent} // ...force the input's value to match the state variable...
                            //onChange={e => setPostContent(e.target.value)} //
                        />
                        <textarea
                            name="postContent"
                            defaultValue={"Task '" + taskName.toString() + "' of project '" + projectName.toString() + "' is updated!"}
                            rows={4}
                            cols={61}
                            sx = {{pt:"15px" }}
                            value={postContent} // ...force the input's value to match the state variable...
                            onChange={e => setPostContent(e.target.value)} //
                        />
                    </Box>
                    <Box  sx={ {color: grey[800], fontSize: "0.70rem", width: "89.2%"}}>
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            labelledBy={"Select"}
                            isCreatable={true}
                        />
                    </Box>
                    <Box sx={ {mt: 2, }}>
                        <Button
                            className="editBtn"
                            onClick=  {(event) => sendMention(event)}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Box>

        </>
    );
};

export default MentionContent;