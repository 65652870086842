import React from "react";
import {Button, Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {
    Header,
    Footer,
    TaskPage,
    ManagementDashboard,
    ManagementReport,
    Performance,
    WorkPaper,
    Administrators,
    Dashboard
} from "./index";
import MainContext from "../../context";
import {useContext, useState} from "react";
import {Test, Test2, Search, Search2} from "./index";
import Page from "./Page";
import SwipeableViews from "react-swipeable-views";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Fade from '@mui/material/Fade';

const MainPage = () => {
    const {dialogContent, snackContent, handleSnackClose, snackOpen,handleDisagree, handleAgree, dialogOpen, handlePageNumber, pageNumber} = useContext(MainContext);
    const vertical= "top"
    const horizontal = 'center'
    const Transition= Fade
    return(
        <>
            <SwipeableViews
                index={pageNumber}
                onChangeIndex={handlePageNumber}
                id = "nononn"
            >
                <Page pageNumber={pageNumber} index={0}>
                    <Dashboard pageTitle = {"Dashboard"} />
                </Page>
                <Page pageNumber={pageNumber} index={1}>
                    <TaskPage pageTitle = {"DOA - Airworthiness"} />
                </Page>
                <Page pageNumber={pageNumber} index={2}>
                    <TaskPage pageTitle = {"DOA - ISM"}/>
                </Page>
                <Page pageNumber={pageNumber} index={3}>
                    <TaskPage pageTitle = {"DOA - Projects"}/>
                </Page>
                <Page pageNumber={pageNumber} index={4}>
                    <TaskPage pageTitle = {"DOA - Proposals"} />
                </Page>
                <Page pageNumber={pageNumber} index={5}>
                    <TaskPage pageTitle = {"POA - Quality System"}/>
                </Page>
                <Page pageNumber={pageNumber} index={6}>
                    <TaskPage pageTitle = {"POA - Projects"}/>
                </Page>
                <Page pageNumber={pageNumber} index={7}>
                    <TaskPage pageTitle = {"R & D"}/>
                </Page>

                <Page pageNumber={pageNumber} index={8}>
                    <ManagementReport pageTitle = {"Progress Report"} />
                </Page>
                {/*<Page pageNumber={pageNumber} index={10}>*/}
                {/*    <Performance pageTitle = {"Man Hours Plan"} />*/}
                {/*</Page>*/}
                <Page pageNumber={pageNumber} index={9}>
                    <WorkPaper pageTitle = {"Performance"} />
                </Page>
                <Page pageNumber={pageNumber} index={10}>
                    <Administrators pageTitle = {"Administrator Panel"} />
                </Page>

            </SwipeableViews>
            {/*<Footer />*/}

            {/*<Box>*/}
            {/*   <React.Fragment >*/}
            {/*        <Dialog*/}
            {/*            open={dialogOpen}*/}
            {/*            keepMounted*/}
            {/*            aria-describedby="alert-dialog-slide-description"*/}
            {/*            id = "dialog"*/}
            {/*        >*/}
            {/*            <DialogTitle>{"Attention"}</DialogTitle>*/}
            {/*            <DialogContent>*/}
            {/*                <DialogContentText id="alert-dialog-slide-description">*/}
            {/*                    {dialogContent}*/}
            {/*                </DialogContentText>*/}
            {/*            </DialogContent>*/}
            {/*            <DialogActions>*/}
            {/*                <Button onClick={handleDisagree}>Cancel</Button>*/}
            {/*                <Button onClick={handleAgree}>Ok</Button>*/}
            {/*            </DialogActions>*/}
            {/*        </Dialog>*/}
            {/*   </React.Fragment>*/}
            {/*</Box>*/}

            <Box>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={snackOpen}
                    onClose={handleSnackClose}
                    message={snackContent}
                    TransitionComponent= {Transition}
                    autoHideDuration={2000}
                    className = "snakeBar"
                />

            </Box>
        </>
    );
}

export default MainPage;

