import {Box, Button, Paper,Table, TableBody,TableCell,TableContainer,TableHead,TableRow,Typography,Menu, MenuItem, IconButton,} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AddLink, ArrowDropDownRounded,ArrowRightRounded, CreateOutlined, DeleteOutlineRounded , AlternateEmailRounded} from '@mui/icons-material';

import {grey} from "@mui/material/colors";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useContext, useState, useEffect, forwardRef} from "react";
import MainContext from "../../context";

import {Progressbar} from "./index"
import DatePicker from 'react-datepicker';

import {subDays, addDays, format} from "date-fns";
import moment from 'moment-timezone';
import { tabsData } from "../../components/data/tabsData.sidebar";
import axios, {AxiosError} from "axios";
import {useAuthUser} from "react-auth-kit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {abs} from "stylis";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



function TableRows({ key1, table_rows, indexes}) {
    const {dedicatedRow, setDedicatedRow, dialogOpenTime, setDialogOpenTime, criticalItem, setCriticalItem, highItem, setHighItem, lowItem, setLowItem, setDialogOpen, setDialogContent , setProjectAnchorEl, deleteInfo, setClickedRow, setError, Staff, setChangeID, userInfo, setProjData, setDeleteInfo,editTask2, anchorEl, setAnchorEl, headCells, projList, projData, onValUpdate, makeMention } = useContext(MainContext);
    const ITEM_HEIGHT = 48;
    const openMenu = Boolean(anchorEl);
    const auth = useAuthUser();

    const handleClickMenu = (event, tableNumber, tableRow) => {
        setAnchorEl(event.currentTarget);
        setDeleteInfo({["projectId"]: tableNumber , ["taskId"]: tableRow});
        setDialogContent("Are you sure to delete the project task!");
        //setClickedRow({["table"]: tableNumber, ["row"]: tableRow });
    };


    const handleMenu = () => {
        setAnchorEl(null);
    };

    const holiDays = tabsData()[2];

    return table_rows.map((rowsData, ind) =>
    {
        let index = indexes[ind];
        let date1 = new Date();
        let date2 = new Date((projData[key1][index][9]));
        let temp1 = date1.getTime();
        let temp2 = date2.getTime();
        let timeDiff = Math.abs(temp1- temp2);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        let date3 = new Date((projData[key1][index][4]));
        let temp3 =  date3.getTime();
        let timeDiff2 = temp3- temp1;

        // const diffDays2 = Math.ceil(timeDiff2 / (1000 * 3600 * 24));
        const diffDays2 = Math.ceil(timeDiff2 / (1000 * 60));

        // let taskTime = projData[key1][index][6];
        // let ind1= taskTime.indexOf(".");
        // let hours0 = "";
        // let minutes0 = "";
        // let required = "";
        // if (ind1 != -1)
        // {
        //     hours0 = parseInt(taskTime.substring(0, index));
        //     minutes0 = parseInt(taskTime.substring(index+1, taskTime.length));
        //     required = hours0 * 60 + minutes0;
        // }else{
        //     required = parseInt(taskTime) * 60;
        // }
        //
        // let ratio = (diffDays2 / required) * 100


        let taskBackColor = "1px solid #8080801c";
        if ((parseInt(projData[key1][index][8]) < 100) && (date3 <= date1)) {
            taskBackColor = "3px solid #da0f27";
        }else if ((parseInt(projData[key1][index][8]) < 50) && (subDays(date3, 1) <= date1)){
            taskBackColor = "3px solid #e9963b";
        }else if (parseInt(projData[key1][index][8]) === 100){
            taskBackColor = "3px solid #ADD3D0";
        }

        let taskStyle = {
            width: '30%',
            borderLeft: taskBackColor,
            borderBottom: "1px solid #8080801c",
        }

        let color = "transparent";
        let fontcolor = "#ffffff";
        if (projData[key1][index][3] === "Done"){
            color = "#ADD3D0";
        }else if (projData[key1][index][3] ==="In progress"){
            color = "#D1913E";
        }else if (projData[key1][index][3] ==="Stuck"){
            color = "#A92420"
        }else if (projData[key1][index][3] ==="Not started"){
            fontcolor = "#757575";
            //color = "#afabaa";
        }
        let color_critical = "transparent";
        if (projData[key1][index][5] === "Critical"){
            color_critical = "#deb277";
        }else if (projData[key1][index][5] ==="High"){
            color_critical = "#d1913e";
        }else if (projData[key1][index][5] ==="Medium"){
            color_critical = "#ecd3b1";
        }else if (projData[key1][index][5] ==="Low"){
            color_critical = "#faf4eb";
        }

        let excludeDate = [];
        let startDates = [];
        let endDates = [];
        let nonFullDate = [];
        let projtaskID = [];

        let excludeDate0 = [];
        let nonFullDate0 = [];
        let startDates0 = [];
        let endDates0 = [];
        let changesId = []

        function checkDateExist(endDate, hours, minutes, state , key){
            let exitHours = []
            let TotalMinutes0 = hours * 60 + minutes;
            let result = ["", ""];
            if (state === "Critical") {
                exitHours = nonFullDate0.filter( (t) => format(t.date, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd') );
            }else{
                exitHours = nonFullDate.filter( (t) => format(t.date, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd') );
            }

            if (exitHours.length > 0)
            {

                let index = "";
                let hours0 = 0;
                let minutes0 = 0;
                let totalminutes = 0;
                let maxIndex = -1;
                for (let i= 0; i < exitHours.length; i++)
                {
                    index = (exitHours[i].holidayName).indexOf(":");
                    hours0 = parseInt((exitHours[0].holidayName).substring(0, index));
                    minutes0 = parseInt((exitHours[0].holidayName).substring(index+1, (exitHours[0].holidayName).length));
                    totalminutes = hours0 * 60 + minutes0;
                    if (totalminutes > TotalMinutes0){
                        if (state === "Critical") {
                            maxIndex = nonFullDate0.findIndex(item => item === exitHours[i]);
                        }else{
                            maxIndex = nonFullDate.findIndex(item => item === exitHours[i]);
                        }
                    }
                }
                result = [false, maxIndex];
            }else
            {
                result = [true, false];
            }

            if (result[0] === true )
            {
                if (state === "Critical") {
                    nonFullDate0.push(key)
                }else{
                    nonFullDate.push(key)
                }
            }
            else
            {
                if (result[1] !== -1){
                    if (state === "Critical") {
                        nonFullDate0[result[1]] = key;
                    }else{
                        nonFullDate[result[1]] = key;
                    }
                }
            }
        }

        function assisst (startDate, state){
            let minutes = 0
            let hours = 0
            let startHour = startDate.getHours();
            let startMinute = startDate.getMinutes();
            let totalMinuteStart = startHour * 60 + startMinute;
            let concurDate = [];
            if (state === "Critical"){
                concurDate = endDates0.filter((date) => format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'))
            }else{
                concurDate = endDates.filter((date) => format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'))
            }
            let max = 8 * 60;
            let breakDone = -1
            if (concurDate.length > 0)
            {
                for (let j = 0; j < concurDate.length; j++)
                {
                    let endHour0 = concurDate[j].getHours();
                    let endMinute0 = concurDate[j].getMinutes();
                    let totalMinute = endHour0 * 60 + endMinute0;

                    if (Math.abs(totalMinuteStart - totalMinute) < 30)
                    {
                        let newEndDate = new Date(startDate)
                        newEndDate.setHours(17,0)
                        let key = {start: subDays(newEndDate , 1) , end: newEndDate };
                        if (state === "Critical")
                        {
                            excludeDate0.push(key)
                            let index0 = nonFullDate0.findIndex(item => item.date === concurDate[j]);
                            if (index0 !== -1) {
                                nonFullDate0.splice(index0, 1);
                            }
                        }else
                        {
                            excludeDate.push(key)
                            let index0 = nonFullDate.findIndex(item => item.date === concurDate[j]);
                            if (index0 !== -1) {
                                nonFullDate.splice(index0, 1);
                            }
                        }
                        breakDone = 1
                        break;
                    }else
                    {
                        if (totalMinute < totalMinuteStart && max < totalMinute)
                        {
                            max = totalMinute
                        }
                    }

                }
            }

            if (breakDone === -1)
            {
                let concurDate = [];
                if (state === "Critical"){
                    concurDate = startDates0.filter((date) => format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'))
                }else{
                    concurDate = startDates.filter((date) => format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd'))
                }
                let min = 17 * 60;
                let breakDone2 = -1
                if (concurDate.length > 0)
                {
                    for (let j = 0; j < concurDate.length; j++)
                    {
                        let endHour0 = concurDate[j].getHours();
                        let endMinute0 = concurDate[j].getMinutes();
                        let totalMinute = endHour0 * 60 + endMinute0;
                        if (totalMinute < min && totalMinute < totalMinuteStart ){
                            min = totalMinute
                        }
                    }
                    if (min < max)
                    {
                        minutes = (startHour * 60 + startMinute - max) % 60;
                        hours = Math.floor((startHour * 60 + startMinute - max ) / 60);
                        let key ={
                            date: startDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                            start: (Math.floor(max / 60)).toString().padStart(2, '0') + ":" + (Math.floor(max % 60)).toString().padStart(2, '0'),
                        }


                        checkDateExist(startDate, hours, minutes, state , key)
                        breakDone2 = 1
                    }
                    else if (max <= min && min < totalMinuteStart)
                    {
                        let newEndDate = new Date(startDate)
                        newEndDate.setHours(17,0)
                        let key = {start: subDays(newEndDate , 1) , end: newEndDate};

                        if (state === "Critical"){
                            excludeDate0.push(key)
                        }else{
                            excludeDate.push(key)
                        }
                        breakDone2 = 1
                    }
                }

                if (breakDone2 === -1)
                {
                    minutes = (startHour * 60 + startMinute - max) % 60;
                    hours = Math.floor((startHour * 60 + startMinute - max ) / 60);
                    let key = {
                        date: startDate,
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                        start: (Math.floor(max / 60)).toString().padStart(2, '0') + ":" + (Math.floor(max % 60)).toString().padStart(2, '0'),
                    };

                    checkDateExist(startDate, hours, minutes, state , key)
                }
            }
        }

        function checkEnd(endDate, state){
            let concurDate = [];
            if (state === "Critical") {
                concurDate = endDates0.filter((date) => format(endDate, 'yyyy-MM-dd') < format(date, 'yyyy-MM-dd') )
            }else{
                concurDate = endDates.filter((date) => format(endDate, 'yyyy-MM-dd') < format(date, 'yyyy-MM-dd') )
            }
            let continues = -1
            if (concurDate.length > 0)
            {
                for (let j = 0; j < concurDate.length; j++)
                {
                    let index0 = "";
                    let sDate = "";
                    if (state === "Critical") {
                        index0 = endDates0.findIndex(item => item === concurDate[j]);
                        sDate = startDates0[index0];
                    }else{
                        index0 = endDates.findIndex(item => item === concurDate[j]);
                        sDate = startDates[index0];
                    }
                    if (sDate <= endDate){
                        continues = index0
                        break;
                    }
                }
            }
            return continues;
        }



        function assisstTwo(endDate, state){
            let endHour = endDate.getHours();
            let endMinute = endDate.getMinutes();
            let result  = checkEnd(endDate, state)
            if (result > -1 )
            {
                let newEndDate = new Date(endDate)
                newEndDate.setHours(17,0)
                let key = {start: subDays(newEndDate , 1), end: newEndDate};
                if (state === "Critical") {
                    excludeDate0.push()
                }else{
                    excludeDate.push()
                }
            }else
            {
                let concurDate = []
                if (state === "Critical") {
                    concurDate = startDates0.filter((date) => format(date, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd'))
                }else{
                    concurDate = startDates.filter((date) => format(date, 'yyyy-MM-dd') === format(endDate, 'yyyy-MM-dd'))
                }
                let listMinutes = [];
                if (concurDate.length > 0)
                {
                    for (let j = 0; j < concurDate.length; j++)
                    {
                        let index0 = ""
                        let nDate = ""
                        if (state === "Critical") {
                            index0 = startDates0.findIndex(item => item === concurDate[j])
                            nDate = endDates0[index0]
                        }else{
                            index0 = startDates.findIndex(item => item === concurDate[j])
                            nDate = endDates[index0]
                        }
                        if (format(concurDate[j], 'yyyy-MM-dd') === format(nDate, 'yyyy-MM-dd'))
                        {
                            let endHour0 = nDate.getHours();
                            let endMinute0 = nDate.getMinutes();
                            let totalMinute = endHour0 * 60 + endMinute0;
                            listMinutes.push(totalMinute)
                        }
                    }
                    let maxMinute = Math.max(...listMinutes);
                    if (17 * 60 - maxMinute > 30)
                    {
                        let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                        let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                        let key ={
                            date: endDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                            start: endHour.toString().padStart(2, '0') + ":" + endMinute.toString().padStart(2, '0')
                        };

                        checkDateExist(endDate, hours, minutes, state , key)

                    }else
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0);
                        let key = {start: subDays(newEndDate , 1), end: newEndDate}
                        if (state === "Critical") {
                            excludeDate0.push(key)
                        }else{
                            excludeDate.push(key)
                        }
                    }
                }else
                {
                    let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                    let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                    let key = {
                        date: endDate,
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                        start: endHour.toString().padStart(2, '0') + ":" + endMinute.toString().padStart(2, '0')
                    };

                    checkDateExist(endDate, hours, minutes, state , key)

                }
            }

        }

        function assisstThree(startDate, endDate){
            console.log("hello")
        }

        const calculateList = (startDate, endDate) =>
        {
            let endHour = endDate.getHours();
            let endMinute = endDate.getMinutes();
            let startHour = startDate.getHours();
            let startMinute = startDate.getMinutes();
            let totalMinuteEnd = endHour * 60 + endMinute;
            let minutes = 0
            let hours = 0




            if ((17 * 60 - endHour * 60 + endMinute) < 30)
            {
                if (startHour * 60 + startMinute - 8 * 60 < 30)
                {
                    let newEndDate = new Date(endDate)
                    newEndDate.setHours(17,0)
                    excludeDate.push({start: subDays(startDate , 1), end: newEndDate})
                }
                else
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate.push({start: startDate , end: newEndDate})
                    }
                    assisst(startDate, "nonCritical")

                }
            } else
            {
                if (startHour * 60 + startMinute - 8 * 60 < 30)
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate.push({start: subDays(startDate , 1), end: (subDays(newEndDate, 1))})
                    }
                    assisstTwo(endDate, "nonCritical")
                }
                else
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate.push({start: startDate, end: (subDays(newEndDate, 1))})
                        assisst(startDate, "nonCritical")
                        assisstTwo(endDate, "nonCritical")

                    }else
                    {
                        minutes = (17 * 60 - endHour * 60 - endMinute) % 60 + (startHour * 60 + startMinute - 8 * 60) % 60;
                        hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60) + Math.floor((startHour * 60 + startMinute - 8 * 60 ) / 60);
                        let key = {
                            date: endDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                            start: (17 - endHour - (startHour - 8)).toString().padStart(2, '0') + ":" + endMinute.toString().padStart(2, '0')
                        }


                        checkDateExist(endDate, hours, minutes, "nonCritical" , key)
                    }
                }
            }
            // console.log("calculateListcalculateList")
            // console.log(excludeDate)
            // console.log(nonFullDate)
        }

        const calculateList03 = (startDate, endDate) => {
            let endHour = endDate.getHours();
            let endMinute = endDate.getMinutes();

            if ((17 * 60 - endHour * 60 + endMinute) < 30)
            {
                endDate.setHours(17,0)
                excludeDate.push({start: subDays(startDate , 1), end: endDate})
            } else
            {
                if (startDate.getDate() !== endDate.getDate())
                {
                    endDate.setHours(17,0)
                    excludeDate.push({start: subDays(startDate , 1), end: (subDays(endDate, 1))})
                }
                let justEndDate = endDate.toISOString().split('T')[0]
                let concurDate = startDates.filter((date) => date.toISOString().split('T')[0] === justEndDate)
                let listMinutes = [];
                if (concurDate.length > 0)
                {
                    for (let j = 0; j < concurDate.length; j++)
                    {
                        let index0 = startDates.findIndex(item => item === concurDate[j])
                        if (concurDate[j].toISOString().split('T')[0] === endDates[index0].toISOString().split('T')[0])
                        {
                            let endHour0 = endDates[index0].getHours();
                            let endMinute0 = endDates[index0].getMinutes();
                            let totalMinute = endHour0 * 60 + endMinute0;
                            listMinutes.push(totalMinute)
                        }
                    }
                    let maxMinute = Math.max(...listMinutes);
                    if (17 * 60 - maxMinute < 30)
                    {
                        let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                        let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                        nonFullDate.push({
                            date: endDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                        })
                    }
                }else
                {
                    let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                    let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                    nonFullDate.push({
                        date: endDate,
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                    })
                }
            }
        }


        const calculateList1 = (startDate, endDate) =>
        {
            let endHour = endDate.getHours();
            let endMinute = endDate.getMinutes();
            let startHour = startDate.getHours();
            let startMinute = startDate.getMinutes();
            let totalMinuteEnd = endHour * 60 + endMinute;
            let minutes = 0;
            let hours = 0;
            let exitDate = nonFullDate0.filter( (t) =>  (format(startDate, 'yyyy-MM-dd') < format(t.date, 'yyyy-MM-dd') ) && (format(t.date, 'yyyy-MM-dd') < format(endDate, 'yyyy-MM-dd') ));
            if (exitDate.length > 0)
            {
                let index0 = "";
                for (let i= exitDate.length -1; 0 <= i; i--)
                {
                    index0 = nonFullDate0.findIndex(item => item === exitDate[i]);
                    nonFullDate0.splice(index0, 1);
                }
            }

            if ((17 * 60 - endHour * 60 + endMinute) < 30)
            {
                if (startHour * 60 + startMinute - 8 * 60 < 30)
                {
                    let newEndDate = new Date(endDate)
                    newEndDate.setHours(17,0)
                    excludeDate0.push({start: subDays(startDate , 1), end: newEndDate})
                }
                else
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate0.push({start: startDate , end: newEndDate})
                    }
                    assisst(startDate, "Critical")

                }
            } else
            {
                if (startHour * 60 + startMinute - 8 * 60 < 30)
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate0.push({start: subDays(startDate , 1), end: (subDays(newEndDate, 1))})
                    }
                    assisstTwo(endDate, "Critical")
                }
                else
                {
                    if (format(startDate, 'yyyy-MM-dd') !== format(endDate, 'yyyy-MM-dd'))
                    {
                        let newEndDate = new Date(endDate)
                        newEndDate.setHours(17,0)
                        excludeDate0.push({start: startDate, end: (subDays(newEndDate, 1))})
                        assisst(startDate, "Critical")
                        assisstTwo(endDate, "Critical")

                    }else
                    {
                        minutes = (17 * 60 - endHour * 60 - endMinute) % 60 + (startHour * 60 + startMinute - 8 * 60) % 60;
                        hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60) + Math.floor((startHour * 60 + startMinute - 8 * 60 ) / 60);
                        let key = {
                            date: endDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
                            start: (17 - endHour - (startHour - 8)).toString().padStart(2, '0') + ":" + endMinute.toString().padStart(2, '0')
                        };

                        checkDateExist(endDate, hours, minutes, "Critical" , key)
                    }
                }
            }
            // console.log("calculateListcalculateList0000")
            // for (let i =0; i< excludeDate0.length; i++){
            //     console.log(excludeDate0[i])
            // }
            //console.log(excludeDate0)
            // console.log("calculateListcalculateList1111")
            // for (let i =0; i< nonFullDate0.length; i++){
            //     console.log(nonFullDate0[i])
            // }
            //console.log(nonFullDate0)
        }

        const calculateList01 = (startDate, endDate) => {
            let endHour = endDate.getHours();
            let endMinute = endDate.getMinutes();





            if ((17 * 60 - endHour * 60 + endMinute) < 30)
            {
                endDate.setHours(17,0)
                excludeDate0.push({start: subDays(startDate , 1), end:  endDate})
            } else
            {
                if (startDate.getDate() !== endDate.getDate())
                {
                    let newdate = subDays(endDate, 1)
                    newdate.setHours(17,0)
                    excludeDate0.push({start: subDays(startDate , 1), end: newdate })
                }
                let justEndDate = endDate.toISOString().split('T')[0]
                let concurDate = startDates0.filter((date) => date.toISOString().split('T')[0] === justEndDate)
                let listMinutes = [];
                if (concurDate.length > 0)
                {
                    for (let j = 0; j < concurDate.length; j++)
                    {
                        let index0 = startDates0.findIndex(item => item === concurDate[j])
                        if (concurDate[j].toISOString().split('T')[0] === endDates0[index0].toISOString().split('T')[0])
                        {
                            let endHour0 = endDates0[index0].getHours();
                            let endMinute0 = endDates0[index0].getMinutes();
                            let totalMinute = endHour0 * 60 + endMinute0;
                            listMinutes.push(totalMinute)
                        }
                    }
                    let maxMinute = Math.max(...listMinutes);
                    if (17 * 60 - maxMinute < 30)
                    {
                        let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                        let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                        nonFullDate0.push({
                            date: endDate,
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                        })
                    }
                }else
                {
                    let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                    let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                    nonFullDate0.push({
                        date: endDate,
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                    })
                }
            }
        }

        function staffTaskInformation(key1, index)
        {
            if (projData[key1][index][2] !== "")
            {
                for (let i=0; i < projList.length; i++ )
                {
                    let tableRows = projData[i].filter(row => row[2] === projData[key1][index][2])
                    for (let j=0; j < tableRows.length; j++ )
                    {
                        if ((tableRows[j][4] !== "") && (tableRows[j][11] !== ""))
                        {

                            let startDate = new Date(tableRows[j][11])
                            let endDate = new Date(tableRows[j][4])
                            startDates.push(startDate)
                            endDates.push(endDate)

                            let index0 =  projData[i].findIndex(item => item === tableRows[j]);
                            let key = [[i, index0],startDate]
                            projtaskID.push(key)
                        }
                    }

                    if (!criticalItem){
                        let tableRows1 = projData[i].filter(row => row[2] === projData[key1][index][2] && row[5] === "Critical")
                        for (let j=0; j < tableRows1.length; j++ )
                        {
                            if ((tableRows1[j][4] !== "") && (tableRows1[j][11] !== ""))
                            {
                                let startDate = new Date(tableRows1[j][11])
                                let endDate = new Date(tableRows1[j][4])
                                startDates0.push(startDate)
                                endDates0.push(endDate)
                            }
                        }
                    }
                    if (!highItem){
                        let tableRows1 = projData[i].filter(row => row[2] === projData[key1][index][2] && row[5] === "High")

                        for (let j=0; j < tableRows1.length; j++ )
                        {
                            if ((tableRows1[j][4] !== "") && (tableRows1[j][11] !== ""))
                            {
                                let startDate = new Date(tableRows1[j][11])
                                let endDate = new Date(tableRows1[j][4])
                                startDates0.push(startDate)
                                endDates0.push(endDate)
                            }
                        }
                    }
                    if (!lowItem){
                        let tableRows1 = projData[i].filter(row => row[2] === projData[key1][index][2] && row[5] === "Low")
                        for (let j=0; j < tableRows1.length; j++ )
                        {
                            if ((tableRows1[j][4] !== "") && (tableRows1[j][11] !== ""))
                            {
                                let startDate = new Date(tableRows1[j][11])
                                let endDate = new Date(tableRows1[j][4])
                                startDates0.push(startDate)
                                endDates0.push(endDate)
                            }
                        }
                    }
                }
            }

        }

        function staffDatesInformation(priority){
            if (priority === "Critical")
            {
                if (startDates0.length > 0 )
                {
                    for (let i = 0; i < startDates0.length; i++)
                    {
                        calculateList1(startDates0[i], endDates0[i])
                    }
                }
            }else
            {
                if (startDates.length > 0 )
                {
                    for (let i = 0; i < startDates.length; i++)
                    {
                        calculateList(startDates[i], endDates[i])
                    }
                }
            }
        }

        function staffOffInformation(staffName){
            const month = new Date().toLocaleString('default', { month: 'long' });
            let Detail = Staff.filter(row => row[0] === staffName)
            let group = auth().user_record[1]
            if (Detail.length > 0 ){
                group = Detail[0][1]
            }
            async function fetchData() {
                try {
                    const response = await axios.post(auth().website + "/GetStaffOff",
                        [staffName, group, month, "false"]
                    );

                    let data0 = (response.data)[0]
                    let data1 = (response.data)[1]

                    let confirmed = []
                    let visit = []

                    for (let i=0; i< data0.length; i++) {
                        let item = data0[i]
                        if (item[2] === "Yes") {
                            confirmed.push(item[1])
                        }
                    }
                    if (confirmed.length > 0){
                        excludeDate = [...excludeDate,confirmed ]
                    }
                    for (let i=0; i< data1.length; i++) {
                        visit.push(data1[i][1])
                    }
                    if (visit.length > 0){
                        excludeDate = [...excludeDate,visit ]
                    }
                } catch (err) {
                    if (err && err instanceof AxiosError)
                        setError(err.response?.data.message);
                    else if (err && err instanceof Error) setError(err.message);
                }
            }
            fetchData()
        }

        const handleClickCell = (key1, index) => {

            excludeDate.splice(0)
            startDates.splice(0)
            endDates.splice(0)
            nonFullDate.splice(0)
            projtaskID.splice(0)

            excludeDate0.splice(0)
            nonFullDate0.splice(0);
            startDates0.splice(0);
            endDates0.splice(0);

            staffOffInformation(projData[key1][index][2])
            staffTaskInformation(key1, index)
            staffDatesInformation(projData[key1][index][5])
        };

        function getTimeInterval1 (mainDate, projNum,tableRow) {
            let taskTime = projData[projNum][tableRow][6];
            let index = taskTime.indexOf(".");
            let hours0 = "";
            let minutes0 = "";
            let startDetermined = -1
            let required = "";
            if (index != -1)
            {
                hours0 = parseInt(taskTime.substring(0, index));
                minutes0 = parseInt(taskTime.substring(index+1, taskTime.length));
                required = hours0 * 60 + minutes0;
            }else{
                required = parseInt(taskTime) * 60;
            }


            let Continue = true;
            let newDate = new Date(mainDate);
            let lastDate = "";

            let ind = 0
            while (Continue)
            {
                let hours = nonFullDate0.filter( (t) => format(t.date, 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd') );
                if (hours.length > 0)
                {
                    let index = (hours[0].holidayName).indexOf(":");
                    let hours0 = parseInt((hours[0].holidayName).substring(0, index));
                    let minutes0 = parseInt((hours[0].holidayName).substring(index+1, (hours[0].holidayName).length));

                    let totalMinutes = hours0 * 60 + minutes0;
                    let dateMinute = 17 * 60 - totalMinutes;
                    // start date

                    //let minutes1 = (17 * 60 - totalMinutes) % 60;
                    //let hours1 = Math.floor(( 17 * 60 - totalMinutes ) / 60);
                    if (startDetermined === -1)
                    {
                        let index11 = (hours[0].start).indexOf(":");
                        let hours1 = parseInt((hours[0].start).substring(0, index11 ));
                        let minutes1 = parseInt((hours[0].start).substring(index11 + 1, (hours[0].start).length));

                        if (mainDate !== newDate){
                            mainDate = new Date(newDate);
                        }
                        mainDate.setHours(hours1,minutes1)
                        startDetermined = 1
                    }

                    // end date
                    let index0 = nonFullDate0.findIndex(item => item === hours[0]);

                    if (totalMinutes < required)
                    {
                        required -= totalMinutes;

                        let newEndDate = new Date(newDate)
                        newEndDate.setHours(17,0)
                        excludeDate0.push({start: subDays(newEndDate , 1) , end: newEndDate});
                        nonFullDate0.splice(index0, 1);

                        newDate = addDays(newDate, 1);

                    }
                    else
                    {
                        // end date
                        dateMinute = dateMinute + required;
                        let minutes1 = (dateMinute) % 60;
                        let hours1 = Math.floor((dateMinute ) / 60);
                        if (Math.abs(dateMinute - 17 * 60) < 30 ){

                            let newEndDate = new Date(newDate)
                            newEndDate.setHours(17,0)
                            excludeDate0.push({start: subDays(newEndDate , 1) , end: newEndDate});
                            nonFullDate0.splice(index0, 1);
                        }else{
                            let minutes = (17 * 60 - hours1 * 60 - minutes1) % 60;
                            let hours = Math.floor((17 * 60 - hours1 * 60 - minutes1) / 60);
                            nonFullDate0[index0].holidayName =  hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                            nonFullDate0[index0].start =  hours1.toString().padStart(2, '0') + ":" + minutes1.toString().padStart(2, '0')
                        }

                        newDate.setHours( hours1 , minutes1 );
                        Continue = false;
                        lastDate = newDate;
                        required = 0;
                        break
                    }
                }else
                {
                    let inExclude = excludeDate0.filter((date) => date.start < newDate  &&  newDate <= date.end);
                    if (inExclude.length > 0 || newDate.getDay() === 4 || newDate.getDay() === 5)
                    {
                        newDate = addDays(newDate, 1);
                    }else
                    {
                        if (startDetermined === -1)
                        {
                            if (mainDate !== newDate)
                            {
                                mainDate = new Date(newDate);
                            }
                            mainDate.setHours(8,0)
                            startDetermined = 1
                        }
                        // end date
                        if (required <= 9 * 60)
                        {
                            //dateMinute = dateMinute + required;
                            let minutes1 = ( 8 * 60 + required ) % 60;
                            let hours1 = Math.floor((8 * 60 + required ) / 60);
                            newDate.setHours( hours1 , minutes1);
                            Continue = false;
                            lastDate = newDate;
                            required = 0;
                            break
                        }else{
                            required -= 9 * 60;
                            newDate = addDays(newDate, 1);
                        }
                    }
                }
                ind += 1
            }
            return [mainDate, lastDate];
        }

        function getTimeInterval (mainDate, taskTime)
        {
            let index = taskTime.indexOf(".");
            let hours0 = "";
            let minutes0 = "";
            let startDetermined = -1
            let required = "";
            if (index != -1)
            {
                hours0 = parseInt(taskTime.substring(0, index));
                minutes0 = parseInt(taskTime.substring(index+1, taskTime.length));
                required = hours0 * 60 + minutes0;
            }else{
                required = parseInt(taskTime) * 60;
            }
            let Continue = true;
            let newDate = new Date(mainDate);
            let lastDate = "";

            while (Continue)
            {
                let hours = nonFullDate.filter((t) => format(t.date, 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd'));
                if (hours.length > 0)
                {
                    let index = (hours[0].holidayName).indexOf(":");
                    let hours0 = parseInt((hours[0].holidayName).substring(0, index));
                    let minutes0 = parseInt( (hours[0].holidayName).substring(index+1, (hours[0].holidayName).length));
                    let totalMinutes = hours0 * 60 + minutes0;
                    let dateMinute = 17 * 60 - totalMinutes;
                    // start date
                    let minutes1 = (17 * 60 - totalMinutes) % 60;
                    let hours1 = Math.floor(( 17 * 60 - totalMinutes ) / 60);

                    if (startDetermined === -1) {

                        let index11 = (hours[0].start).indexOf(":");
                        let hours11 = parseInt((hours[0].start).substring(0, index11 ));
                        let minutes11 = parseInt((hours[0].start).substring(index11 + 1, (hours[0].start).length));
                        mainDate.setHours(hours11,minutes11)
                        startDetermined = 1
                    }
                    // end date
                    if (totalMinutes < required)
                    {
                        required -= totalMinutes
                        newDate = addDays(newDate, 1);
                    }
                    else
                    {
                        // end date
                        dateMinute = dateMinute + required;
                        let minutes1 = (dateMinute) % 60;
                        let hours1 = Math.floor((dateMinute ) / 60);
                        newDate.setHours( hours1 , minutes1 );
                        Continue = false;
                        lastDate = newDate;
                        required = 0;
                        break
                    }
                }

                else
                {
                    let inExclude = []
                    if (excludeDate.length > 0){
                        inExclude = excludeDate.filter((date) => format(date.start, 'yyyy-MM-dd') < format(newDate, 'yyyy-MM-dd') && format(newDate, 'yyyy-MM-dd') <= format(date.end, 'yyyy-MM-dd'));
                    }
                    if (inExclude.length > 0 || newDate.getDay() === 4 || newDate.getDay() === 5)
                    {
                        newDate = addDays(newDate, 1);
                    }
                    else
                    {
                        const day = newDate.getDay();
                        // if (day === 1 || day === 3){
                        //     if (startDetermined === -1)
                        //     {
                        //         mainDate.setHours(12,0)
                        //         startDetermined = 1
                        //     }
                        //     if (required <= 5 * 60)
                        //     {
                        //         let minutes1 = ( 12 * 60 + required ) % 60;
                        //         let hours1 = Math.floor((12 * 60 + required) / 60);
                        //         newDate.setHours( hours1 , minutes1);
                        //         Continue = false;
                        //         lastDate = newDate;
                        //         required = 0;
                        //         break
                        //     }else{
                        //         required -= 5 * 60;
                        //         newDate = addDays(newDate, 1);
                        //     }
                        // }
                        // else{
                        if (startDetermined === -1) {
                            mainDate.setHours(8,0)
                            startDetermined = 1
                        }
                        if (required <= 9 * 60)
                        {
                            let minutes1 = ( 8 * 60 + required ) % 60;
                            let hours1 = Math.floor((8 * 60 + required ) / 60);
                            newDate.setHours( hours1 , minutes1);
                            Continue = false;
                            lastDate = newDate;
                            required = 0;
                            break
                        }else{
                            required -= 9 * 60;
                            newDate = addDays(newDate, 1);
                        }
                       // }
                    }
                }
            }
            return [mainDate, lastDate];
        }

        const isWeekday = (date) => {
            const day = date.getDay();
            let UTCDate = (convertLocalToUTCDate(date)).toString();
            let doesExist  = holiDays.includes(UTCDate)
            return day !== 4 && day !== 5 && !doesExist;
        };

        function convertLocalToUTCDate(date) {
            if (!date) {
                return date
            }
            date = new Date(date)
            date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
            return date
        }

        const converToLocal = (Date0) =>
        {
            if (!Date0) {
                return Date0
            }
            let substr = Date0.substring(0, 10);
            let mainDate = new Date(substr)
            let hours = parseInt(Date0.substring(11, 13));
            let minutes = parseInt(Date0.substring(14, 16));
            mainDate.setHours(hours,minutes)
            return mainDate
        }

        function  dateAllocation(mainDate, projNum,tableRow){


            console.log("changesId")
            console.log(changesId)

            // for (let i=0; i < sortingArr.length; i++ )
            // {
            //     let startDate = sortingArr[i]
            //     let endDate = sortingArr2[i]
            //     let projNum0 = projtaskID[i][0][0]
            //     let tableRow0 = projtaskID[i][0][1]
            //     if (new Date(data[projNum][tableRow][4]) < endDate  && (data[projNum0][tableRow0][5] === "Low" || data[projNum0][tableRow0][5] === "Medium"))
            //     {
            //         const output = getTimeInterval1(startDate, projNum0 , tableRow0 )
            //         data[projNum0][tableRow0][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
            //         data[projNum0][tableRow0][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
            //         calculateList1(output[0] ,output[1])
            //         changesId.push([projNum0,tableRow0])
            //     }
            // }
        }

        const handleDateChange = (mainDate, projNum,tableRow) =>
        {
            const data = [...projData];

            if (mainDate === null)
            {
                setDedicatedRow([projNum,tableRow])
                let result  = checkEnd(new Date(data[projNum][tableRow][4]), "nonCritical")
                data[projNum][tableRow][11] = "";
                data[projNum][tableRow][4] = "";
                changesId.push([projNum,tableRow]);
                if (result > -1){
                    let tRow = projtaskID[result][0][1]
                    let tProj = projtaskID[result][0][0]
                    let tStart = data[tProj][tRow][11]
                    let tHour = projData[tProj][tRow][6]
                    data[tProj][tRow][11] = "";
                    data[tProj][tRow][4] = "";
                    setProjData(data);
                    handleClickCell(tProj, tRow)
                    const output = getTimeInterval(new Date(tStart), tHour )
                    data[tProj][tRow][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                    data[tProj][tRow][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
                    changesId.push([tProj,tRow])
                }
            }else{
                if (projNum === dedicatedRow[0] && tableRow === dedicatedRow[1] )
                {
                    //dateAllocation(mainDate, projNum,tableRow)
                    const output = getTimeInterval1(mainDate, projNum,tableRow )
                    data[projNum][tableRow][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                    data[projNum][tableRow][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
                    let startDate = new Date(data[projNum][tableRow][11])
                    let endDate = new Date(data[projNum][tableRow][4])
                    startDates0.push(startDate)
                    endDates0.push(endDate)
                    calculateList1(startDate,endDate)
                    let sortingArr = startDates
                    let sortingArr2 = endDates;
                    sortingArr.sort(function(i, j) {
                        return new Date(i).getTime() - new Date(j).getTime();
                    });
                    sortingArr2.sort(function(i, j) {
                        return new Date(i).getTime() - new Date(j).getTime();
                    });
                    projtaskID.sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b));

                    changesId.push([projNum,tableRow]);
                    for ( let i=0; i < sortingArr.length; i++ )
                    {
                        let startDate = sortingArr[i]
                        let endDate = sortingArr2[i]
                        let projNum0 = projtaskID[i][0][0]
                        let tableRow0 = projtaskID[i][0][1]
                        if (endDate <= new Date(data[projNum][tableRow][11]))
                        {
                            startDates0.push(startDate)
                            endDates0.push(endDate)
                            calculateList1(startDate,endDate)
                        }else
                        {
                            if ((data[projNum0][tableRow0][5] === "High" && highItem) || (data[projNum0][tableRow0][5] === "Critical" && criticalItem)
                                || (data[projNum0][tableRow0][5] === "Low" && lowItem))
                            {
                                //let newDate = new Date()
                                let newDate = new Date(data[projNum0][tableRow0][11])
                                let pretaskId = data[projNum0][tableRow0][10];

                                if (pretaskId !== "")
                                {
                                    let index0 = data[projNum0].findIndex(item => item[0] === pretaskId );
                                    if (index0 !== -1)
                                    {
                                        newDate = new Date(data[projNum0][index0][11])
                                    }
                                }
                                const output = getTimeInterval1( newDate, projNum0 , tableRow0 )
                                data[projNum0][tableRow0][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                                data[projNum0][tableRow0][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
                                startDates0.push(output[0])
                                endDates0.push(output[1])
                                calculateList1(output[0] ,output[1])
                                changesId.push([projNum0,tableRow0])
                            }
                        }
                    }

                    let newchangedArr = [...changesId]
                    let projNum01 = "";
                    let tableNum01 = "";

                    while (newchangedArr.length > 0)
                    {

                        projNum01 = newchangedArr[0][0];
                        tableNum01 = newchangedArr[0][1];
                        if (data[projNum01][tableNum01][0] !== "")
                        {
                            let index0 = data[projNum01].findIndex(row => row[10] === data[projNum01][tableNum01][0].toString());
                            if (index0 !== -1)
                            {
                                if (data[projNum01][index0][11] < data[projNum01][tableNum01][4]) {
                                    handleClickCell(projNum01, index0)
                                    let newDate = new Date(data[projNum01][tableNum01][4])
                                    const output = getTimeInterval1(newDate, projNum01, index0)
                                    data[projNum01][index0][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                                    data[projNum01][index0][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
                                    let startDate = new Date(data[projNum01][index0][11])
                                    let endDate = new Date(data[projNum01][index0][4])
                                    startDates0.push(startDate)
                                    endDates0.push(endDate)
                                    calculateList1(startDate, endDate)
                                    let sortingArr = startDates
                                    let sortingArr2 = endDates;
                                    sortingArr.sort(function (i, j) {
                                        return new Date(i).getTime() - new Date(j).getTime();
                                    });
                                    sortingArr2.sort(function (i, j) {
                                        return new Date(i).getTime() - new Date(j).getTime();
                                    });
                                    projtaskID.sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b));

                                    changesId.push([projNum, tableRow]);
                                    newchangedArr.push([projNum, tableRow]);
                                    for (let i = 0; i < sortingArr.length; i++) {
                                        let startDate = sortingArr[i]
                                        let endDate = sortingArr2[i]
                                        let projNum0 = projtaskID[i][0][0]
                                        let tableRow0 = projtaskID[i][0][1]
                                        if (endDate <= new Date(data[projNum][tableRow][11])) {
                                            startDates0.push(startDate)
                                            endDates0.push(endDate)
                                            calculateList1(startDate, endDate)
                                        } else {
                                            if ((data[projNum0][tableRow0][5] === "High" && highItem) || (data[projNum0][tableRow0][5] === "Critical" && criticalItem)
                                                || (data[projNum0][tableRow0][5] === "Low" && lowItem)) {
                                                let newDate = new Date(data[projNum0][tableRow0][11])
                                                let pretaskId = data[projNum0][tableRow0][12];
                                                if (pretaskId !== 0) {
                                                    let index0 = data[projNum0].findIndex(item => item[0] === pretaskId);
                                                    newDate = new Date(data[projNum0][index0][11])
                                                }

                                                const output = getTimeInterval1(newDate, projNum0, tableRow0)
                                                data[projNum0][tableRow0][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                                                data[projNum0][tableRow0][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');
                                                startDates0.push(output[0])
                                                endDates0.push(output[1])
                                                calculateList1(output[0], output[1])
                                                changesId.push([projNum0, tableRow0])
                                                newchangedArr.push([projNum0, tableRow0]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        newchangedArr.splice(0, 1);
                    }
                }
                else
                {
                    const output = getTimeInterval(mainDate, projData[projNum][tableRow][6])
                    data[projNum][tableRow][11] = format(output[0], 'yyyy-MM-dd kk:mm:ss');
                    data[projNum][tableRow][4] = format(output[1], 'yyyy-MM-dd kk:mm:ss');

                    changesId.push([projNum,tableRow])
                }
            }

            setProjData(data);
            setChangeID(changesId);
            setCriticalItem(false);
            setHighItem(false);
            setLowItem(false);

            console.log("end of program")
        };

        const insertMiddleTask = () =>
        {
            let table = deleteInfo.projectId;
            let row= deleteInfo.taskId;
            let data2 = [...projData];
            //let preindex = data2[table][row][12];

            setClickedRow(projData[table][row][0]);
            data2[table].splice(row, 0, ["", "", "","", "", "",0, 0, 0, "","" ,"", 0]);
            setProjData(data2);
            setDialogOpenTime(true);
            setDedicatedRow([table,row])
            console.log("[table,row])")
            console.log([table,row])
        }

        const ExampleCustomInput = forwardRef(({value, onClick, onChange}, ref) => (
            <input
                value={value}
                className="example-custom-input"
                onClick={onClick}
                onChange={onChange}
                ref={ref}
            />
        ));

        const handleDialogOpen = () => {
            if (deleteInfo.taskId === -1) {
                setDialogOpen(true);
                setProjectAnchorEl(null);
            }else
            {
                setDialogOpen(true);
                setAnchorEl(null);
            }
        };

        return (
            <>
                <TableRow key={index}  >
                    <TableCell  sx = {taskStyle} >
                        <div>
                            <IconButton
                                key = {key1.toString() + index.toString() + "101" }
                                aria-label="more"
                                id="long-button"
                                aria-controls={openMenu ? 'long-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClickMenu (event, key1, index) }
                                sx = {{padding: 0 }}
                            >
                                <MoreVertIcon key = {key1.toString() + index.toString() + "1002"} sx={{color: "#6e6b6b80", height: "1.4rem"}}/>
                            </IconButton>
                            <Menu
                                key = {key1.toString() + index.toString() + "102"}
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenu}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem
                                    key = {key1.toString() + index.toString() + "104"}
                                    onClick={(event) => editTask2()}
                                    disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                                >
                                    <CreateOutlined/>
                                </MenuItem>

                                <MenuItem
                                    key = {key1.toString() + index.toString() + "105"}
                                    onClick = {(event) => handleDialogOpen()}
                                    disabled={(!(userInfo.name === projList[key1][4]))}
                                >
                                    <DeleteOutlineRounded   />
                                </MenuItem>

                                <MenuItem
                                    key = {key1.toString() + index.toString() + "106"}
                                    onClick=  {(event) => insertMiddleTask()}
                                    disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                                >
                                    <AddLink   />
                                </MenuItem>

                                <MenuItem
                                    key = {key1.toString() + index.toString() + "107"}
                                    onClick=  {(event) => makeMention()}
                                    disabled={(!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                                >
                                    <AlternateEmailRounded   />
                                </MenuItem>
                            </Menu>
                        </div>

                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[0].width} >
                        <Typography
                            variant = "caption"
                            sx = {{ color: "#626060", width: headCells[0].width, display: "block"}}
                        >
                            {projData[key1][index][0]}
                        </Typography>
                        {/*<input*/}
                        {/*    key = {key1.toString() + index.toString() + 0}*/}
                        {/*    type="number"*/}
                        {/*    value={projData[key1][index][0]}*/}
                        {/*    //onChange=  {(event) => onValUpdate(key1, index, 1, event)}*/}
                        {/*    name="taskId"*/}
                        {/*    className="selectOption"*/}
                        {/*    style = {{width: headCells[0].width }}*/}
                        {/*    //disabled*/}
                        {/*/>*/}

                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[1].width} >
                        <input
                            key = {key1.toString() + index.toString() + 1}
                            type="text"
                            value={projData[key1][index][1]}
                            onChange=  {(event) => onValUpdate(key1, index, 1, event)}
                            name="task"
                            className="selectOption"
                            style = {{width: headCells[1].width }}
                            disabled={ (userInfo.name !== projList[key1][4])}
                        />
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[2].width}>
                        <select
                            key = {key1.toString() + index.toString() + 2}
                            name="owner"
                            value={projData[key1][index][2]}
                            onChange=  {(event) => onValUpdate(key1, index, 2, event)}
                            className="selectOption"
                            style={{width: headCells[2].width}}
                            disabled={ (userInfo.name !== projList[key1][4])}
                        >
                            <option value="">  </option>
                            {Staff.map((member) => (
                                <option key={member[0]} value={member[0]}>
                                    {member[0]}
                                </option>
                            ))}

                        </select>
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[7].width} sx={{backgroundColor: color_critical}}>
                        <select
                            key = {key1.toString() + index.toString() + 7}
                            name="priority"
                            value={projData[key1][index][5]}
                            onChange=  {(event) => onValUpdate(key1, index, 5, event)}
                            className="selectOption myClass"
                            style = {{color: fontcolor, width: headCells[7].width}}
                            disabled={ (userInfo.name !== projList[key1][4])}
                        >
                            <option value=""> </option>
                            <option value = "Critical">  Critical  </option>
                            <option value = "High"> High </option>
                            <option value = "Medium"> Medium </option>
                            <option value = "Low"> Low </option>
                        </select>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[4].width}>
                        <input
                            key = {key1.toString() + index.toString() + 4}
                            type="number"
                            value={projData[key1][index][6]}
                            onChange=  {(event) => onValUpdate(key1, index, 6, event)}
                            name="estimatedHours"
                            className="selectOption"
                            style = {{width : headCells[4].width }}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                            disabled={ (userInfo.name !== projList[key1][4])}
                        />
                    </TableCell>
                    <TableCell className ="taskInput" sx={{width: headCells[5].width, fontSize: "0.75rem"}}
                               onClick={(event) => handleClickCell (key1, index) }
                    >
                        <DatePicker
                            selected={converToLocal(projData[key1][index][11])}
                            name="Start date"
                            className = "selectOption"
                            customInput={<ExampleCustomInput />}
                            key = {key1.toString() + index.toString() + 5}
                            onChange={(date) => handleDateChange(date,key1, index)}
                            excludeDateIntervals = { ((key1 === dedicatedRow[0]) && (index === dedicatedRow[1])) ? excludeDate0 : excludeDate}
                            //excludeDateIntervals = {projData[key1][index][5] === "Critical" ? excludeDate0 : excludeDate}
                            dateFormat="MM/dd/yyyy HH:mm"
                            filterDate = {isWeekday}
                            //holidays = {projData[key1][index][5] === "Critical" ? nonFullDate0 : nonFullDate}
                            holidays = {((key1 === dedicatedRow[0]) && (index === dedicatedRow[1])) ? nonFullDate0 : nonFullDate}
                            isClearable
                            sx={{width: headCells[5].width, fontSize: "0.60rem"}}
                        />
                    </TableCell>
                    <TableCell className ="taskInput" sx={{width: headCells[6].width, fontSize: "0.75rem"}}
                    >
                        <DatePicker
                            selected={converToLocal(projData[key1][index][4])}
                            className = "selectOption"
                            customInput={<ExampleCustomInput />}
                            name="Due date"
                            key = {key1.toString() + index.toString() + 6}
                            disabled
                            dateFormat="MM/dd/yyyy HH:mm"
                            sx={{width: headCells[6].width}}
                        />
                    </TableCell>
                    <TableCell className ="taskInput"  width = {headCells[3].width} sx = {{backgroundColor : color }}>
                        <select
                            key = {key1.toString() + index.toString() + 3}
                            name="status"
                            value={projData[key1][index][3]}
                            onChange=  {(event) => onValUpdate(key1, index, 3, event)}
                            //className="selectOption"
                            className="selectOption myClass"
                            style = {{color: fontcolor, width: headCells[3].width}}
                            disabled={ (!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                        >
                            <option value=""> </option>
                            <option value = "Done" > Done </option>
                            <option value = "In progress"> In progress </option>
                            <option value = "Stuck"  > Stuck </option>
                            <option value = "Not started"> Not started </option>
                        </select>
                    </TableCell>

                    <TableCell className ="taskInput" width = {headCells[8].width}>
                        <input
                            key = {key1.toString() + index.toString() + 8}
                            type="number"
                            value={projData[key1][index][7]}
                            onChange=  {(event) => onValUpdate(key1, index, 7, event)}
                            name="actualHours"
                            className="selectOption"
                            style = {{width : headCells[8].width }}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                            disabled={ (!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                        />
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[9].width}
                               key = {key1.toString() + index.toString() + 9}
                    >
                        <Progressbar className ="selectOption"  tableNumber={key1} tableRow = {index}
                        />
                    </TableCell>
                    <TableCell className ="taskInput" width = {headCells[10].width}>
                        <select
                            key = {key1.toString() + index.toString() + 10}
                            name="dependence"
                            value={projData[key1][index][10]}
                            onChange=  {(event) => onValUpdate(key1, index, 10, event)}
                            className="selectOption"
                            style={{width: headCells[10].width}}
                            disabled={ (!((userInfo.name === projList[key1][4]) || (userInfo.name === projData[key1][index][2])))}
                        >
                            <option value="">  </option>
                            {projData[key1].map((id) => (
                                <option key={id[0]} value={id[0]}>
                                    {id[0]}
                                </option>
                            ))}
                        </select>
                            {/*<Typography*/}
                        {/*    key = {key1.toString() + index.toString() + 9}*/}
                        {/*    name="update"*/}
                        {/*    variant = "caption"*/}
                        {/*    sx = {{ color: "#626060", width: headCells[9].width, display: "block"}}*/}

                        {/*>*/}
                        {/*    {diffDays > 0 ? diffDays.toString() + " day(s) ago" : "Today"}*/}
                        {/*</Typography>*/}
                    </TableCell>
                </TableRow>
            </>

        );
    });
}


const TableRows2 = (props) => {
    const { index, table_rows, indexes, item } = props;

    const {dialogOpenTime, setDialogOpenTime, criticalItem, setCriticalItem, highItem, setHighItem, lowItem, setLowItem, setDeleteInfo, setAnchorEl, dialogContent, setDialogContent, setError, setProjectAnchorEl, setSnackContent, setDialogOpen, setSnackOpen, setProjList, dialogOpen, type, deleteInfo, setTemp, userInfo, setOpen, open, headCells, projData, setProjData, projList, setOpenModal} = useContext(MainContext);
    const handleClick = (id) => {
        setOpen((prevstate) => ({ ...prevstate, [id]: !prevstate[id]}));
    };
    const auth = useAuthUser();
    const addRowTable = (e, tableNum, tableRow) => {
        let data2 =[...projData];
        let preindex = 0
        let lastindex = data2[tableNum].length
        if (lastindex > 0){
            preindex = data2[tableNum][lastindex - 1][0]
        }
        data2[tableNum].push(["", "", "","", "", "",0, 0, 0, "", "" ,"",preindex]);
        setProjData(data2);
    };

    const editProject = (e , index) =>
    {
        setOpenModal(true);
        setTemp({["index"]:index, ["number"]: projList[index][1], ["title"]: projList[index][2], ["customer"]: projList[index][3], ["manager"]:  projList[index][4], ["priority"]: projList[index][10], ["type"]: type});
    }

    const handleDisagree = () => {
        setDialogOpen(false);
    };

    const handleAgree = () =>
    {
        if (deleteInfo.taskId === -1)
        {
            deleteProject2(deleteInfo.projectId)
        }
        else
        {
            deleteTask2(deleteInfo.projectId, deleteInfo.taskId)
        }
        setDialogOpen(false);
    };

    const deleteProject2 = async (tableIndex) => {
        //e.preventDefault()
        setError("");
        setProjectAnchorEl(null);
        if (projList[tableIndex][0] !== "")
        {
            try {
                const response = await axios.post(auth().website + "/DeleatProject",
                    [projList[tableIndex][1]]
                );
                if (response.data === "Group Delete"){
                    let reducedArr = [...projList];
                    reducedArr.splice(tableIndex, 1);
                    setProjList(reducedArr)
                    let reducedArr2 = [...projData];
                    reducedArr2.splice(tableIndex, 1);
                    setProjData(reducedArr2)
                    setSnackContent("Confirmation: The project has been successfully deleted!")
                    setSnackOpen(true);
                }else{
                    setSnackContent("Warning: The project deletion failed!")
                    setSnackOpen(true);
                    setError("Warning: The project deletion failed!")
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else
        {
            let reducedArr = [...projList];
            reducedArr.splice(tableIndex, 1);
            setProjList(reducedArr)
            let reducedArr2 = [...projData];
            reducedArr2.splice(tableIndex, 1);
            setProjData(reducedArr2)
        }
    }

    const deleteTask2 = async(tablekey, tablerow) => {
        setError("");
        setAnchorEl(null);
        let index0 = projData[tablekey].findIndex(item => item[12] === projData[tablekey][tablerow][0])
        let previousTask = projData[tablekey][tablerow][12]
        //let staff = projData[tablekey][tablerow][2]
        console.log("projList[tablekey][1]projList[tablekey][1]")
        console.log(projList[tablekey][1])
        console.log(projData[tablekey][tablerow][0])
        console.log(index0)
        let preId = "";
        if (index0 !== -1){
            preId = projData[tablekey][index0][0]
            console.log(projData[tablekey][index0][0])
        }
        console.log(previousTask)
        if (projData[tablekey][tablerow][0] !== "")
        {
            let updatedData = [ projList[tablekey][1], projData[tablekey][tablerow][0],preId, previousTask ]
            try{
                const response = await axios.post(auth().website +  "/RemoveTask",
                    updatedData
                );
                if (response.data == "Task Delete")
                {
                    let reducedArr = [...projData];
                    if (index0 !== -1){
                        reducedArr[tablekey][index0][12] = previousTask;
                    }
                    reducedArr[tablekey].splice(tablerow, 1);
                    setProjData(reducedArr)
                    setSnackContent("Confirmation: The project task has been successfully deleted!")
                    setSnackOpen(true);
                }
                else
                {
                    setSnackContent("Warning: The project task deletion failed!")
                    setSnackOpen(true);
                    setError("Warning: The project task deletion failed!")
                }
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }else{
            let reducedArr = [...projData];
            if (index0 !== -1) {
                reducedArr[tablekey][index0][12] = previousTask;
            }

            reducedArr[tablekey].splice(tablerow, 1);
            setProjData(reducedArr)
            setSnackContent("Confirmation: The project task has been successfully deleted!")
            setSnackOpen(true);
        }
        // setDeleteInfo({...deleteInfo, ["taskId"]: -2, ["staffId"]: staff});
        // setDialogContent("Do you want to change the time interval of upcoming tasks!");
        // setDialogOpen(true);
        // setAnchorEl(null);
    }

    const handleDisagreeTime = () => {
        setDialogOpenTime(false);
    }

    const handleAgreeTime = () => {
        setCriticalItem(document.getElementById("critical").checked);
        setHighItem(document.getElementById("high").checked);
        setLowItem(document.getElementById("low").checked);
        setDialogOpenTime(false);
    }


    return(
        <>
            <Paper sx={{width: '100%', }}>
                <Box sx = {{pt:1, pb:1}} >
                    <IconButton
                        onClick={() => handleClick(index)}
                        aria-label="expand"
                        size="small"
                        //color="primary"
                        sx={{color: "#153250"}}
                    >
                        {open[index] ? <ArrowDropDownRounded/>
                            :<ArrowRightRounded/> }
                    </IconButton>
                    <Typography variant="subtitle1" sx={{
                        display: "inline-block",
                        fontWeight: "bold",
                        color: "#153250"
                    }}>
                        {item[2]}
                    </Typography>
                </Box>
                <Box>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <TableContainer sx={{textAlign: "center", backgroundColor: "#F0F2F3"}}>
                            <Table sx={{width: "100%"}}>

                                <TableHead id = "headCell">
                                    <TableRow className ="myTasksRow">
                                        <TableCell className="taskInput" sx={{width: "20px", backgroundColor:"#ffffff",borderLeft: "unset"}}>
                                            <Typography variant = "caption" sx={{width: "20px", display: "block"}}>  </Typography>
                                        </TableCell>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                className="taskInput"
                                                key={headCell.id}
                                                sx={{border: "unset", backgroundColor:"#ffffff", }}
                                                width={headCell.width}
                                            >
                                                <Typography variant = "caption" width={headCell.width} sx={{fontWeight: 700, display: "block"}}>  {headCell.label}  </Typography>

                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRows
                                        key1={index}
                                        table_rows={table_rows}
                                        indexes = {indexes}
                                    />

                                    <TableRow sx={{backgroundColor: "#ffffff"}}>
                                        <TableCell colSpan={14} className="operationBtn">
                                            <Button className="editBtn" sx={{m: "5px"}}
                                                //key={"btn0" + key.toString()}
                                                    onClick={(e) => addRowTable(e, index, projData[index].length - 1)}
                                                    disabled={(userInfo.name !== projList[index][4])}
                                            >
                                                + Add task
                                            </Button>

                                            <Button className="editBtn" sx={{m: "5px"}}
                                                    onClick={(e) => editProject(e, index)}
                                                    disabled={!userInfo.admin}
                                            >
                                                Edit / Delete project
                                            </Button>

                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid container  sx = {{fontSize: "0.85rem", textAlign: "left", mt: 1, mb: 1,}} color = {grey[600] }>
                            <Grid xs= {4}>
                                <Typography variant = "caption"  sx ={{fontWeight : 700, display: "inline-block", pl: 2, }}> Manager:</Typography>
                                <Typography variant = "caption" sx ={{display: "inline-block" ,fontWeight : 400, pl: 1,}}> {item[4]} </Typography>
                            </Grid>
                            <Grid xs= {4}>
                                <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Customer:</Typography>
                                <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> {item[3]}</Typography>
                            </Grid>
                            <Grid xs= {4}>
                                <Typography variant = "caption" sx ={{fontWeight : 700, display: "inline-block", }}>  Priority:</Typography>
                                <Typography variant = "caption" sx ={{fontWeight : 400, display: "inline-block", pl: 1,}}> {item[10]}</Typography>
                            </Grid>
                        </Grid>

                    </Collapse>
                </Box>
            </Paper>

            <Box>
                <React.Fragment >
                    <Dialog
                        open={dialogOpen}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        id = "dialog"
                    >
                        <DialogTitle>{"Attention"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {dialogContent}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250"}} onClick={handleDisagree}>Cancel</Button>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250"}} onClick={handleAgree}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </Box>

            <Box>
                <React.Fragment >
                    <Dialog
                        open={dialogOpenTime}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        id = "dialog"
                    >
                        <DialogTitle>{"Attention"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Typography variant="caption">
                                Please select tasks category thier's time can be postponed:
                                </Typography> <br/>
                                <Typography variant="caption" > <Checkbox  id = "critical" /> Critical Tasks  </Typography> <br/>
                                <Typography variant="caption" > <Checkbox  id = "high" /> High Priority Task  </Typography> <br/>
                                <Typography variant="caption" > <Checkbox  id = "low" /> Low Priority Task  </Typography> <br/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250", color: "white"}} onClick={handleDisagreeTime}>Cancel</Button>
                            <Button className="editBtn" sx={{p: "12px", backgroundColor: "#153250", color: "white"}} onClick={handleAgreeTime}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </Box>
        </>
    );
};

export default TableRows2;