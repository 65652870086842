import React from "react";
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import Logo from "../../assets/logo.png";
import {Font} from '@react-pdf/renderer';

import MyCustomFont from '../../assets/fonts/Anton-Regular.ttf';

Font.register({
    family: 'AntonFamily',
    src: MyCustomFont
})

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
        fontFamily: "AntonFamily",
    },
    text: {
        margin: 12,
        fontSize: 10,
        textAlign: "justify",
        fontFamily: "AntonFamily",

    },
    image: {
        marginVertical: 10,
        marginHorizontal: "33%",
        width: "30%",
        textAlign: "center",
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: "center",
        color: "black",
        fontFamily: "AntonFamily",
    },
    subheader: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
        fontFamily: "AntonFamily",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        fontFamily: "AntonFamily",
    },
});
// projectManager = {}
// taskHolder = {}
// offsiteActivities = {}
// Overtime = {}
// offDays = {}
// Overdue = {}
// Trips = {}
// Absence = {}
const PDFFile = ({staffDetail, projectManager, taskHolder, offsiteActivities, Overtime, offDays, Overdue, Trips, Absence}) => {

    const pageColors = ['#fdfdfd', '#f67280', '#c06c84'];

    const pages = [{text: 'Performance Report', image: Logo }]

    return (
        <Document>
            <Page  style={{...styles.body, backgroundColor: pageColors[0]}}>
                <Image style={styles.image} src={pages[0].image} />
                <Text style={styles.header} fixed> {staffDetail.name} </Text>
                <Text style={styles.subheader} fixed> {staffDetail.position} </Text>

                <Text style={styles.text}>
                    Project Manager: {" "} {projectManager}
                </Text>
                <Text style={styles.text}>
                    Task Holder: {" "} {taskHolder}
                </Text>
                <Text style={styles.text}>
                    Offsite Activities: {" "} {offsiteActivities}
                </Text>
                <Text style={styles.text}>
                    Overtime: {" "} {Overtime}
                </Text>
                <Text style={styles.text}>
                    Off Days: {" "} {offDays}
                </Text>
                <Text style={styles.text}>
                    Over due: {" "} {Overdue}
                </Text>
                <Text style={styles.text}>
                    Trips: {" "} {Trips}
                </Text>
                <Text style={styles.text}>
                    Absence: {" "} {Absence}
                </Text>



                {/*<Text*/}
                {/*    style={styles.pageNumber}*/}
                {/*    render={({ pageNumber, totalPages }) =>*/}
                {/*        `${pageNumber} / ${totalPages}`*/}
                {/*    }*/}
                {/*/>*/}
            </Page>
        </Document>
    );
};

export default PDFFile;