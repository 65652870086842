import React from "react";
import {useContext, useState} from "react";
import {Box,Typography, Tabs, Tab, AppBar, Button, Paper, Divider} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import {grey} from "@mui/material/colors";
import {NewUser} from "./index";
import Grid from "@mui/material/Unstable_Grid2";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Administrators = ({pageTitle}) =>
{
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return(
        <>
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "#F0F2F3",
                }}
            >
                <Box sx = {{mb: 2, ml:5, mr:5,mt: 2}}>
                    <h3 className= "headerClass"  > {pageTitle} </h3>
                    <Typography  variant="caption" sx={{color: "#031830"}} > Create user, manager & admin account, here! </Typography>
                </Box>
                <Divider variant="middle"  sx={{ height: 2,  mt: 1,  backgroundColor:grey[400]}} />
                <Box
                    sx = {{
                        height: "95%",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: "1.2rem",
                        borderRadius: 5,
                        mb: 2, ml:4, mr:4,mt: 2
                    }}
                    className = "background3 "
                >
                    <Box sx={{bgcolor:'background.paper', backgroundColor: "unset", height: "100%",}}  className = "adminpage">
                        <Grid container >
                            <Grid xs = {2}> </Grid>
                            <Grid xs = {6}
                                  id = "calender"
                            >
                                <Box sx={{mt: 5}}>
                                    <NewUser />
                                </Box>
                            </Grid>
                            <Grid xs = {4}> </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Administrators;