import {Drawer} from "@mui/material";
import {useContext} from "react";
import MainContext from "../../context";
import {MentionContent} from "./index";
const MentionDrawer = () => {
    const {mentionOpen, setMentionOpen} = useContext(MainContext)
    return(
        <Drawer
            open={mentionOpen}
            variant="temporary"
            onClose={() => {setMentionOpen(false)}}
            sx={{
                "& .MuiDrawer-paper": {
                    width: 400,
                    overflowY: "scroll",
                    overflowX: "hidden",
                },
                display: {
                    xs: "block",
                },

            }}
        >
            <MentionContent />
        </Drawer>
    );
}
export default MentionDrawer;