import React, {useContext, useEffect, useState} from "react";
import {subDays} from "date-fns";
import MainContext from "../../context";
import axios, {AxiosError} from "axios";
import {tabsData} from "../../components/data/tabsData.sidebar";

function convertUTCToLocalDate(date)
{
    if (!date) {
        return date
    }
    let dateType = new Date(date);
    let Hour = dateType.getUTCHours();
    let Minute = dateType.getUTCMinutes();
    //let Hour = dateType.getHours();
    //let Minute = dateType.getMinutes();

    date = new Date(dateType.getUTCFullYear(), dateType.getUTCMonth(), dateType.getUTCDate())
    date.setHours(Hour,Minute)
    return date
}

function convertLocalToUTCDate(date) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}
export function TimeCalculate(props) {

    const {checkInOutTable,offSiteDates,projList, projData, setError, setConfirmedOffDate,setDeclinedOffDate,setWaitingOffDate ,setOffSiteDates } = useContext(MainContext);

    // const holiDays = tabsData()[2];

    let excludeDate = [];
    let startDates = [];
    let endDates = [];
    let nonFullDate = [];
    const data = tabsData()[1];
    let userDetail = data.filter(row => row.name === props);

    // useEffect( () => {
    //     setError("");
    //     let confirmedDate = [];
    //     let declinedDate = [];
    //     let waitingDate = [];
    //     let offsiteDates = [];
    //     const month = new Date().toLocaleString('default', { month: 'long' });
    //     async function fetchData() {
    //         try {
    //             const response = await axios.post("http://localhost:5000/GetStaffOff",
    //                 [props, userDetail[0].group , month]
    //             );
    //             // setStaffOffDays((response.data)[0])
    //             for (let i=0; i<(response.data)[0].length; i++){
    //                 let item = (response.data)[0][i]
    //                 if (item[2] === "Yes"){
    //                     confirmedDate.push(convertUTCToLocalDate(item[1]))
    //                 }else if (item[2] === "No"){
    //                     declinedDate.push(convertUTCToLocalDate(item[1]))
    //                 }else{
    //                     let key = { index:item[0], date: convertUTCToLocalDate(item[1]).toISOString().split('T')[0], status: ""};
    //                     waitingDate.push(key)
    //                 }
    //             }
    //             setConfirmedOffDate(confirmedDate)
    //             setDeclinedOffDate(declinedDate)
    //             setWaitingOffDate(waitingDate)
    //             for(let i=0; i<(response.data)[1].length; i++)
    //             {
    //                 let item = (response.data)[1][i];
    //                 let key = { date: convertUTCToLocalDate(item[1]), message: item[2] };
    //                 offsiteDates.push(key)
    //             }
    //             setOffSiteDates(offsiteDates)
    //             checkInOutTable("","", "new")
    //
    //         } catch (err) {
    //             if (err && err instanceof AxiosError)
    //                 setError(err.response?.data.message);
    //             else if (err && err instanceof Error) setError(err.message);
    //         }
    //     }
    //     fetchData()
    // }, []);




    for (let i=0; i < projList.length; i++ )
    {
        if (projData.length > 0)
        {
            let tableRows = projData[i].filter(row => row[2] === props)
            for (let j=0; j < tableRows.length; j++ )
            {
                if ((tableRows[j][4] !== "") && (tableRows[j][11] !== ""))
                {
                    let startDate = new Date(tableRows[j][11])
                    let endDate = new Date(tableRows[j][4])
                    startDates.push(startDate)
                    endDates.push(endDate)
                }
            }
        }
    }


    if (startDates.length > 0)
    {
        for (let i = 0; i < startDates.length; i++)
        {
            let endHour = endDates[i].getHours();
            let endMinute = endDates[i].getMinutes();

            if ((17 * 60 - endHour * 60 + endMinute) < 30)
            {
                excludeDate.push({start: subDays(startDates[i] , 1), end: endDates[i]})
            } else
            {
                if (startDates[i].getDate() !== endDates[i].getDate())
                {
                    excludeDate.push({start: subDays(startDates[i] , 1), end: subDays(endDates[i], 1)})
                }
                let justEndDate = endDates[i].toISOString().split('T')[0]
                let concurDate = startDates.filter((date) => date.toISOString().split('T')[0] === justEndDate)
                let listMinutes = [];
                if (concurDate.length > 0)
                {
                    for (let j = 0; j < concurDate.length; j++)
                    {
                        let index0 = startDates.findIndex(item => item === concurDate[j])
                        if (concurDate[j].toISOString().split('T')[0] === endDates[index0].toISOString().split('T')[0])
                        {
                            let endHour0 = endDates[index0].getHours();
                            let endMinute0 = endDates[index0].getMinutes();
                            let totalMinute = endHour0 * 60 + endMinute0;
                            listMinutes.push(totalMinute)
                        }
                    }
                    let maxMinute = Math.max(...listMinutes);
                    if (17 * 60 - maxMinute < 30)
                    {
                        let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                        let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                        nonFullDate.push({
                            date: endDates[i],
                            holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                        })
                    }
                }else
                {
                    let minutes = (17 * 60 - endHour * 60 - endMinute) % 60;
                    let hours = Math.floor((17 * 60 - endHour * 60 - endMinute) / 60);
                    nonFullDate.push({
                        date: endDates[i],
                        holidayName: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
                    })
                }
            }
        }
    }

    return [excludeDate , nonFullDate  ];
}