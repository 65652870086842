import {Box} from "@mui/material";
import {SidebarTabs}  from "./index";

const SidebarContent = () => {
    return(
        <>
            <Box
                sx={{ height: "100vh", overflow: "hidden", textAlign: "center",  justifyContent: "center", backgroundColor: "#153250" , }}
            >
                <SidebarTabs />
            </Box>

        </>
    );
}
export default SidebarContent;